// import Inferno from 'inferno';
// import Component from 'inferno-component';
import React, {useCallback} from 'react';
import Helmet from 'react-helmet';

import styles from './Main.css';
import StepsList from './StepsList';
import Button from './Button';
import WindowStep from './WindowStep';
import MaterialStep from './MaterialStep';
import GlassStep from './GlassStep';
import ColorStep from './ColorStep';
import AccessoriesStep from './AccessoriesStep';
import OrderStep from './OrderStep';
import WindowVisualization from './WindowVisualization';
import Stream from '../libs/Stream';
import orderStream from '../streams/orderStream';
import appStateStream from '../streams/appStateStream';
import createOrderItemActionStream from '../streams/createOrderItemActionStream';
import openStepActionStream from '../streams/openStepActionStream';
import I18n from '../libs/I18n';
import languageStream from '../streams/languageStream';
import {useStream} from '../hooks/useStream';
import constants from '../internals/constants';

const CS_CZ = constants.CS_CZ;
const SK_SK = constants.SK_SK;

let i18n = new I18n();

export let Main = () => {
  useStream(languageStream);
  useStream(appStateStream);
  useStream(orderStream);

  let handleStartClick = useCallback(() => {
    let order = orderStream.value;

    if (!order.items.length) {
      createOrderItemActionStream.push(true);
    }

    openStepActionStream.push('window');
  }, []);

  let {openedStep} = appStateStream.value;
  let order = orderStream.value;
  let orderItem;

  if (order.currentItem !== null) {
    orderItem = order.items[order.currentItem];
  }

  return (
    <main className={styles.root}>
      <Helmet>
        <title>{i18n.translate`Html - title`}</title>
        <meta name="description" content={i18n.translate`Html - description`} />
      </Helmet>
      {orderItem && orderItem.window ? (
        <div className={styles.visualization}>
          <WindowVisualization orderItem={orderItem} />
        </div>
      ) : (
        <div className={styles.intro}>
          <h1 className={styles.appHeading}>{i18n.translate`Main - intro - heading`}</h1>
          <p className={styles.appDescription}>{i18n.translate`Main - intro - text 1`}</p>
          <p className={styles.buttons}>
            <Button
              type="default"
              size="normal"
              label={i18n.translate`Main - intro - button start`}
              handleClick={handleStartClick}
            />
            {languageStream.value.current === CS_CZ || languageStream.value.current === SK_SK ? (
              <Button
                type="outlined"
                size="normal"
                label={i18n.translate`Main - intro - button videotutorial`}
                link="https://www.youtube.com/watch?v=EPMNmCnhHOg"
              />
            ) : null}
          </p>
        </div>
      )}
      <StepsList />
      <WindowStep isOpen={openedStep === 'window'} />
      <MaterialStep isOpen={openedStep === 'material'} />
      <GlassStep isOpen={openedStep === 'glass'} />
      <ColorStep isOpen={openedStep === 'color'} />
      <AccessoriesStep isOpen={openedStep === 'accessories'} />
      <OrderStep isOpen={openedStep === 'order'} />
    </main>
  );
};
