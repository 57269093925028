import {curry1} from './curry1';


/**
 * Optimized internal two-arity curry function.
 *
 * @private
 * @category Function
 * @param {Function} fn The function to curry.
 * @return {Function} The curried function.
 */
export function curry2(fn) {
  return function fn2(a, b) {
    if (arguments.length === 0) {
      return fn2;
    }

    if (arguments.length === 1) {
      return curry1((_b) => fn(a, _b));
    }

    return fn(a, b);
  };
}
