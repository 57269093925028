export default {
	// platform
	CLIENT_PLATFORM: 'client',
	SERVER_PLATFORM: 'server',

	// locale
	EN_US: 'en-US',
	CS_CZ: 'cs-CZ',
	SK_SK: 'sk-SK',
	DE_DE: 'de-DE',
	DE_AT: 'de-AT',
	EN: 'en',
	CS: 'cs',
	SK: 'sk',
	DE: 'de',
};
