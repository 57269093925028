// import Inferno from 'inferno';
// import Component from 'inferno-component';
import React from 'react';

import styles from './MaterialStep.css';
import openStepActionStream from '../streams/openStepActionStream';
import dataStream from '../streams/dataStream';
import orderStream from '../streams/orderStream';
import {Material} from '../types/data';
import selectMaterialActionStream from '../streams/selectMaterialActionStream';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import link from '../utils/link';
import getOrderItemPrice from '../utils/getOrderItemPrice';
import Stream from '../libs/Stream';
import I18n from '../libs/I18n';
import languageStream from '../streams/languageStream';
import getConvertedPrice from '../utils/getConvertedPrice';
import config from '../config';

const VAT_MODIFIER = config.VAT_MODIFIER;

let i18n = new I18n();

export interface MaterialDetailProps {
  material: Material;
  isSelected: boolean;
  handleClick: (material: Material) => void;
}

export class MaterialDetail extends React.Component<MaterialDetailProps, {}> {
  render() {
    let order = orderStream.value;
    let orderItem;

    if (order.currentItem !== null) {
      orderItem = order.items[order.currentItem];
    }

    if (!this.props) {
      return;
    }

    let {windowPrice: currentWindowPrice} = getOrderItemPrice(orderItem);
    let {windowPrice: nextWindowPrice} = getOrderItemPrice(
      Object.assign({}, orderItem, {
        material: {
          id: this.props.material.id,
          name: this.props.material.name,
          priceModifier: this.props.material.priceModifier,
          rebate: this.props.material.rebate,
        },
      }),
    );
    let delta = getConvertedPrice((nextWindowPrice - currentWindowPrice) * VAT_MODIFIER[languageStream.value.current]);

    return (
      <div
        className={styles.materialDetail + (this.props.isSelected ? ` ${styles.isSelected}` : '')}
        onClick={this.handleClick}
      >
        <h4 className={styles.materialDetailHeading}>{this.props.material.name[languageStream.value.current]}</h4>

        <figure className={styles.materialDetailPhoto}>
          <img src={link('assets', this.props.material.image)} alt="" />
        </figure>

        <p className={styles.materialDetailTitle}>{this.props.material.title[languageStream.value.current]}</p>
        <p className={styles.materialDetailDescription}>
          {this.props.material.description1[languageStream.value.current]}
        </p>
        <p className={styles.materialDetailDescription}>
          {this.props.material.description2[languageStream.value.current]}
        </p>
        <p className={styles.materialDetailPriceComparison}>
          {delta >= 0
            ? i18n.translate`MaterialStep - material detail - positive price - ${delta}:c`
            : i18n.translate`MaterialStep - material detail - negative price - ${delta * -1}:c`}
        </p>
      </div>
    );
  }

  handleClick = (event) => {
    if (this.props) {
      this.props.handleClick(this.props.material);
    }
  };
}

export interface MaterialStepProps {
  isOpen: boolean;
}

export default class MaterialStep extends React.Component<MaterialStepProps, {}> {
  onLanguageStream: Stream<{}>;

  componentDidMount() {
    this.onLanguageStream = languageStream.on(() => {
      // console.log('MaterialStep 1');
      // requestAnimationFrame(() => {
      //   console.log('MaterialStep 2');
      this.forceUpdate();
      // });
    });
  }

  componentWillUnmount() {
    this.onLanguageStream.end.push(true);
  }

  render() {
    let {materials} = dataStream.value;
    let orderItem =
      orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];

    if (!orderItem) {
      return null;
    }

    return (
      <section className={styles.root + (this.props && this.props.isOpen ? ` ${styles.isOpen}` : '')}>
        <div className={styles.wrapper}>
          <h1 className={styles.heading}>{i18n.translate`MaterialStep - heading 1`}</h1>
          <span className={styles.closeButton}>
            <Button type="invisible" size="large" icon="cross" handleClick={this.handleCloseClick} />
          </span>

          <div className={styles.material}>
            <ul className={styles.materialsList}>
              {materials.map((material, index) => (
                <li key={index}>
                  <MaterialDetail
                    material={material}
                    isSelected={orderItem && orderItem.material && material.id === orderItem.material.id}
                    handleClick={this.handleSelectMaterial}
                  />
                </li>
              ))}
            </ul>

            <p className={styles.warning}>{i18n.translate`MaterialStep - warning`}</p>

            <ButtonGroup align="center">
              <Button
                type="flat"
                size="large"
                label={i18n.translate`MaterialStep - button 1`}
                isDisabled={!(orderItem && orderItem.material)}
                handleClick={this.handleCloseClick}
              />
            </ButtonGroup>
          </div>
        </div>
      </section>
    );
  }

  handleCloseClick = () => {
    openStepActionStream.push(null);
  };

  handleSelectMaterial = (material) => {
    selectMaterialActionStream.push({
      id: material.id,
      name: material.name,
      priceModifier: material.priceModifier,
      rebate: material.rebate,
    });
  };
}
