import styles from './styles';
import i18nStrings from './i18nStrings';
import constants from '../internals/constants';

const CS_CZ = constants.CS_CZ;
const SK_SK = constants.SK_SK;
const DE_DE = constants.DE_DE;
const DE_AT = constants.DE_AT;

export interface Config {
  styles: any;
  i18nStrings: any;
  urlAppRoot: string;
  ONE_PLN_IN_CZK: number;
  ONE_CZK_IN_EUR: number;
  VAT_MODIFIER: number;
  MARGIN: number;
  WEIGHT_OF_M2_IN_KG: number;
  ALT_NAME_HEIGHT: number;
}

let config: Config = {
  styles,
  i18nStrings,
  urlAppRoot: process.env.NODE_ENV === 'production' ? '/' : '/', // should be e.g. `/` or `/root`
  ONE_PLN_IN_CZK: 5.5,
  ONE_CZK_IN_EUR: 0.041781,
  VAT_MODIFIER: {
    [CS_CZ]: 1.21,
    [SK_SK]: 1.2,
    [DE_DE]: 1.19,
    [DE_AT]: 1.20,
  },
  MARGIN: 1.45,
  SUNBLIND_MARGIN: 1,
  WEIGHT_OF_M2_IN_KG: 35,
  ALT_NAME_HEIGHT: 1900,
  COEFFICIENT: 2.065,
};

export default config;
