import _ from 'lodash';
import {Color} from '../types/data';

export default function findMaterialWithinColor(color: Color, materialId: Number): Boolean {
    // find material within color's price modifiers
    let found = false;

    color.priceModifiers.forEach(priceModifier => {
        let foundMaterialId = _.find(priceModifier.materialIds, (value) => { return value === materialId });

        if (foundMaterialId) {
            found = true;
        }
    });

    return found;
}
