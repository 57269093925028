import $ from 'jquery';
import _ from 'lodash';

import Stream from '../libs/Stream';
import {Step} from '../types/structure';
import openStepActionStream from './openStepActionStream';
import dataStream from './dataStream';
import orderStream from './orderStream';

export interface AppState {
  openedStep: Step;
}

let appStateStream: Stream<AppState> = new Stream({
  openedStep: null,
});

// (global -as any).appStateStream = appStateStream;

let freezeViewport = (event) => {
  event.preventDefault();

  if ($.contains($('#app')[0], event.target)) {
  } else {
    event.preventDefault();
  }
};

let top = 0;

let stopBodyScrolling = (isOn) => {
  if (isOn) {
    top = $('body').scrollTop();

    $('html').addClass('hasOverlayBox');

    // document.body.addEventListener('touchmove', freezeViewport, false);
  } else {
    $('html').removeClass('hasOverlayBox');

    $('body').scrollTop(top);

    // document.body.removeEventListener('touchmove', freezeViewport, false);
  }
};

openStepActionStream.on((step) => {
  let appState = _.cloneDeep(appStateStream.value);

  // if (step) {
  //   (global as any).ga('send', 'event', 'Steps', 'Open step', step, step);
  // } else {
  //   (global as any).ga('send', 'event', 'Steps', 'Close step', appState.openedStep, appState.openedStep);
  // }

  if (step === 'material') {
    let order = orderStream.value;
    let orderItem = order.currentItem === null ? undefined : order.items[order.currentItem];
    let {windowTypes} = dataStream.value;
    let windowType = orderItem && orderItem.window ? _.find(windowTypes, {id: orderItem.window.typeId}) : undefined;

    if (!(windowType && _.isFinite(windowType.materialId))) {
      appState.openedStep = step;

      stopBodyScrolling(true);

      appStateStream.push(appState);
    }
  } else {
    appState.openedStep = step;

    if (step) {
      stopBodyScrolling(true);
    } else {
      stopBodyScrolling(false);
    }

    appStateStream.push(appState);
  }
});

export default appStateStream;
