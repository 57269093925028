/* eslint-disable complexity */

import React from 'react';
import _ from 'lodash';

import styles from './StepsList.css';
import Stream from '../libs/Stream';
import orderStream from '../streams/orderStream';
import dataStream from '../streams/dataStream';
import openStepActionStream from '../streams/openStepActionStream';
import createOrderItemActionStream from '../streams/createOrderItemActionStream';
import destroyOrderItemActionStream from '../streams/destroyOrderItemActionStream';
import selectOrderItemActionStream from '../streams/selectOrderItemActionStream';
import increaseOrderItemCountActionStream from '../streams/increaseOrderItemCountActionStream';
import decreaseOrderItemCountActionStream from '../streams/decreaseOrderItemCountActionStream';
import {OrderItem} from '../types/structure';
import Button from './Button';
import Icon from './Icon';
import I18n from '../libs/I18n';
import languageStream from '../streams/languageStream';
import config from '../config';

const ALT_NAME_HEIGHT = config.ALT_NAME_HEIGHT;

let i18n = new I18n();

export interface OrderItemLinkProps {
  index: number;
  orderItem: OrderItem;
  handleShow: (index: number) => void;
  handleAdd: (index: number) => void;
  handleRemove: (index: number) => void;
  handleDelete: (index: number) => void;
}

export class OrderItemLink extends React.Component<OrderItemLinkProps, {}> {
  render() {
    if (!this.props) {
      return null;
    }

    let order = orderStream.value;

    return (
      <div className={styles.orderItemLink + (this.props.index === order.currentItem ? ` ${styles.isSelected}` : '')}>
        <span className={styles.orderItemLinks}>
          <Button type="full" label="+" size="small" handleClick={this.handleAddClick} />
          <Button type="full" label="-" size="small" handleClick={this.handleRemoveClick} />
          <Button type="full" icon="bin" size="small" handleClick={this.handleDeleteClick} />
        </span>
        <span className={styles.orderItemCount}>{this.props.orderItem.count} ks</span>
        <span className={styles.orderItemName}>
          <a href="#" onClick={this.handleShowClick}>{i18n.translate`OrderStep - order item link - heading - ${this
            .props.index + 1}`}</a>
        </span>
        <span className={styles.orderItemLinkIcon}>
          <Icon id="cog" />
        </span>
      </div>
    );
  }

  handleShowClick = (event) => {
    event.preventDefault();

    if (this.props && this.props.handleShow) {
      this.props.handleShow(this.props.index);
    }
  };

  handleAddClick = (event) => {
    // event.preventDefault();

    if (this.props && this.props.handleAdd) {
      this.props.handleAdd(this.props.index);
    }
  };

  handleRemoveClick = (event) => {
    // event.preventDefault();

    if (this.props && this.props.handleRemove) {
      this.props.handleRemove(this.props.index);
    }
  };

  handleDeleteClick = (event) => {
    // event.preventDefault();

    if (this.props && this.props.handleDelete) {
      this.props.handleDelete(this.props.index);
    }
  };
}

export default class StepsList extends React.Component<{}, {}> {
  onOrderStream: Stream<{}>;
  onLanguageStream: Stream<{}>;

  componentDidMount() {
    this.onOrderStream = orderStream.on(() => {
      requestAnimationFrame(() => this.forceUpdate());
    });
    this.onLanguageStream = languageStream.on(() => {
      // console.log('StepsList 1');
      // requestAnimationFrame(() => {
      //   console.log('StepsList 2');
      this.forceUpdate();
      // });
    });
  }

  componentWillUnmount() {
    this.onOrderStream.end.push(true);
    this.onLanguageStream.end.push(true);
  }

  render() {
    let order = orderStream.value;
    let window;
    let windowType;
    let material;
    let glass;
    let color;
    let sunblind;
    let innerWindowsill;
    let outerWindowsill;
    let accessories;
    let orderItem =
      orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];

    if (orderItem) {
      window = orderItem.window;
      windowType = window ? _.find(dataStream.value.windowTypes, {id: window.typeId}) : undefined;
      material = orderItem.material;
      glass = orderItem.glass;
      color = orderItem.color;
      sunblind = orderItem.sunblind;
      innerWindowsill = orderItem.innerWindowsill;
      outerWindowsill = orderItem.outerWindowsill;
      accessories = orderItem.accessories;
    }

    return (
      <nav className={styles.root}>
        <ol className={styles.steps}>
          <li key="1" className={`${styles.step} ${styles.hasOverlay} ${window ? '' : styles.isEmpty}`}>
            <div className={styles.stepWrapper}>
              <p className={styles.stepHeading}>
                <span>{i18n.translate`StepsList - window type - heading`}</span>
              </p>

              <div className={styles.stepContent}>
                {window ? (
                  <p>
                    {window.width} × {window.height} mm
                  </p>
                ) : null}
                {window && windowType ? (
                  <p>
                    {windowType.altName && window.height >= ALT_NAME_HEIGHT
                      ? windowType.altName[languageStream.value.current]
                      : windowType.name[languageStream.value.current]}
                  </p>
                ) : null}
                {window && windowType && windowType.hasLeftRightVariants ? (
                  <p>
                    {i18n.translate`windowType - variant`}:{' '}
                    {window.variant === 1
                      ? i18n.translate`windowType - variant - left`
                      : i18n.translate`windowType - variant - right`}
                  </p>
                ) : null}
                <p className={styles.stepButtonAlt}>
                  <Button
                    type="outlined"
                    size="small"
                    label={i18n.translate`StepsList - window type - button`}
                    handleClick={this.handleWindowStepClick}
                  />
                </p>
              </div>

              <p className={styles.stepButton}>
                <Button
                  type="outlined"
                  color="inverse"
                  label={i18n.translate`StepsList - window type - button`}
                  isDisabled={/*!orderItem*/ false}
                  handleClick={this.handleWindowStepClick}
                />
              </p>
            </div>
          </li>

          <li key="2" className={`${styles.step} ${styles.hasOverlay} ${material ? '' : styles.isEmpty}`}>
            <div className={styles.stepWrapper}>
              <p className={styles.stepHeading}>{i18n.translate`StepsList - material - heading`}</p>

              <div className={styles.stepContent}>
                {material ? (
                  <p>
                    {material.id === 1 && (windowType.id === 13 || windowType.id === 16)
                      ? i18n.getTranslationString('TROCAL 76')
                      : material.name[languageStream.value.current]}
                  </p>
                ) : null}
                <p className={styles.stepButtonAlt}>
                  <Button
                    type="outlined"
                    size="small"
                    label={i18n.translate`StepsList - material - button`}
                    handleClick={this.handleMaterialStepClick}
                  />
                </p>
              </div>

              <p className={styles.stepButton}>
                <Button
                  type="outlined"
                  color="inverse"
                  label={i18n.translate`StepsList - material - button`}
                  isDisabled={!orderItem || !orderItem.window || _.isFinite(orderItem.window.materialId)}
                  handleClick={this.handleMaterialStepClick}
                />
              </p>
            </div>
          </li>

          <li key="3" className={`${styles.step} ${styles.hasOverlay} ${glass ? '' : styles.isEmpty}`}>
            <div className={styles.stepWrapper}>
              <p className={styles.stepHeading}>{i18n.translate`StepsList - glass - heading`}</p>

              <div className={styles.stepContent}>
                {glass ? <p>{glass.name[languageStream.value.current]}</p> : null}
                <p className={styles.stepButtonAlt}>
                  <Button
                    type="outlined"
                    size="small"
                    label={i18n.translate`StepsList - glass - button`}
                    handleClick={this.handleGlassStepClick}
                  />
                </p>
              </div>

              <p className={styles.stepButton}>
                <Button
                  type="outlined"
                  color="inverse"
                  label={i18n.translate`StepsList - glass - button`}
                  isDisabled={!orderItem || !orderItem.window}
                  handleClick={this.handleGlassStepClick}
                />
              </p>
            </div>
          </li>

          <li key="4" className={`${styles.step} ${styles.hasOverlay} ${color ? '' : styles.isEmpty}`}>
            <div className={styles.stepWrapper}>
              <p className={styles.stepHeading}>{i18n.translate`StepsList - color - heading`}</p>

              <div className={styles.stepContent}>
                {color ? (
                  <p>
                    {color.name[languageStream.value.current]} (
                    {color.sidesCount > 1 ? i18n.translate`color - two sides` : i18n.translate`color - one side`})
                  </p>
                ) : null}
                <p className={styles.stepButtonAlt}>
                  <Button
                    type="outlined"
                    size="small"
                    label={i18n.translate`StepsList - color - button`}
                    handleClick={this.handleColorStepClick}
                  />
                </p>
              </div>

              <p className={styles.stepButton}>
                <Button
                  type="outlined"
                  color="inverse"
                  label={i18n.translate`StepsList - color - button`}
                  isDisabled={!orderItem || !orderItem.window}
                  handleClick={this.handleColorStepClick}
                />
              </p>
            </div>
          </li>

          <li
            key="5"
            className={`${styles.step} ${styles.hasOverlay} ${
              sunblind || innerWindowsill || outerWindowsill || accessories ? '' : styles.isEmpty
            }`}
          >
            <div className={styles.stepWrapper}>
              <p className={styles.stepHeading}>{i18n.translate`StepsList - accessories - heading`}</p>

              <div className={styles.stepContent}>
                {sunblind ? (
                  <p>
                    {i18n.translate`AccessoriesStep - heading 6`}: {sunblind.color[languageStream.value.current]}
                  </p>
                ) : null}
                {innerWindowsill ? (
                  <p>
                    {i18n.translate`AccessoriesStep - heading 7`}:{' '}
                    {innerWindowsill.color?.[languageStream.value.current] ?? ''}{' '}
                    {` ${innerWindowsill.width} mm × ${innerWindowsill.depth} mm`}
                  </p>
                ) : null}
                {outerWindowsill ? (
                  <p>
                    {i18n.translate`AccessoriesStep - heading 8`}:{' '}
                    {outerWindowsill.color?.[languageStream.value.current] ?? ''}{' '}
                    {` ${outerWindowsill.width} mm × ${outerWindowsill.depth} mm`}
                  </p>
                ) : null}
                {accessories
                  ? accessories.map((accessory, index) => (
                      <p key={index}>{accessory.name[languageStream.value.current]}</p>
                    ))
                  : null}
                <p className={styles.stepButtonAlt}>
                  <Button
                    type="outlined"
                    size="small"
                    label={i18n.translate`StepsList - accessories - button`}
                    handleClick={this.handleAccessoriesStepClick}
                  />
                </p>
              </div>

              <p className={styles.stepButton}>
                <Button
                  type="outlined"
                  color="inverse"
                  label={i18n.translate`StepsList - accessories - button`}
                  isDisabled={!orderItem || !orderItem.window}
                  handleClick={this.handleAccessoriesStepClick}
                />
              </p>
            </div>
          </li>

          <li key="6" className={styles.step + ` ${order.items.length ? '' : styles.isEmpty}`}>
            <div className={styles.stepWrapper}>
              <p className={styles.stepHeading}>{i18n.translate`StepsList - windows - heading`}</p>

              <div className={styles.stepContent}>
                <ul className={styles.stepList}>
                  {order.items.length
                    ? order.items.map((orderItem, index) => (
                        <li key={index}>
                          <OrderItemLink
                            index={index}
                            orderItem={orderItem}
                            handleShow={this.handleShowOrderItem}
                            handleAdd={this.handleAddOrderItem}
                            handleRemove={this.handleRemoveOrderItem}
                            handleDelete={this.handleDeleteOrderItem}
                          />
                        </li>
                      ))
                    : null}
                </ul>

                {orderStream.value.items && orderStream.value.items.length ? (
                  <p className={styles.stepButton}>
                    <Button
                      type="outlined"
                      label={i18n.translate`StepsList - windows - button`}
                      overlayLabel={i18n.translate`StepsList - windows - button overlay`}
                      handleClick={this.handleAddNewOrderItem}
                    />
                    {/* <Button type="outlined" color="secondary" label={i18n.translate `StepsList - windows - button`} handleClick={this.handleAddNewOrderItem} /> */}
                  </p>
                ) : null}
              </div>
            </div>
          </li>

          <li key="7" className={styles.step + ` ${order.items.length ? '' : styles.isEmpty}`}>
            <div className={styles.stepWrapper}>
              <p className={styles.stepHeading}>{i18n.translate`StepsList - order - heading`}</p>

              <div className={styles.stepContent}>
                {/*<p>{i18n.translate `StepsList - order - info`}</p>*/}
                {orderStream.value.items.length ? (
                  <p className={styles.stepButton}>
                    <Button
                      type="full"
                      label={i18n.translate`StepsList - order - button`}
                      isDisabled={!orderStream.value.items.length}
                      handleClick={this.handleOrderStepClick}
                    />
                  </p>
                ) : null}
              </div>
            </div>
          </li>
        </ol>
      </nav>
    );
  }

  handleWindowStepClick = () => {
    let orderItem =
      orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];

    if (!orderItem) {
      // return;
      createOrderItemActionStream.push(true);
    }

    openStepActionStream.push('window');
  };

  handleMaterialStepClick = () => {
    let orderItem =
      orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];

    if (!orderItem || !orderItem.window) {
      return;
    }

    openStepActionStream.push('material');
  };

  handleGlassStepClick = () => {
    let orderItem =
      orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];

    if (!orderItem || !orderItem.window) {
      return;
    }

    openStepActionStream.push('glass');
  };

  handleColorStepClick = () => {
    let orderItem =
      orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];

    if (!orderItem || !orderItem.window) {
      return;
    }

    openStepActionStream.push('color');
  };

  handleAccessoriesStepClick = () => {
    let orderItem =
      orderStream.value.currentItem === null ? undefined : orderStream.value.items[orderStream.value.currentItem];

    if (!orderItem || !orderItem.window) {
      return;
    }

    openStepActionStream.push('accessories');
  };

  handleOrderStepClick = () => {
    openStepActionStream.push('order');
  };

  handleAddNewOrderItem = () => {
    createOrderItemActionStream.push(true);
  };

  handleDeleteOrderItem = (index) => {
    destroyOrderItemActionStream.push(index);
  };

  handleShowOrderItem = (index) => {
    selectOrderItemActionStream.push(index);
  };

  handleAddOrderItem = (index) => {
    increaseOrderItemCountActionStream.push(index);
  };

  handleRemoveOrderItem = (index) => {
    decreaseOrderItemCountActionStream.push(index);
  };
}
