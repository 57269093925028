import config from '../config';
import constants from '../internals/constants';
import languageStream from '../streams/languageStream';


const SK_SK = constants.SK_SK;
const DE_DE = constants.DE_DE;
const DE_AT = constants.DE_AT;

export default function getConvertedPrice(value) {
	if (languageStream.value && (languageStream.value.current === SK_SK || languageStream.value.current === DE_DE || languageStream.value.current === DE_AT)) {
		// console.log([value, config.ONE_CZK_IN_EUR, value * config.ONE_CZK_IN_EUR]);
		return value * config.ONE_CZK_IN_EUR;
	}

	return Math.round(value);
}
