import moment from 'moment';

import I18n from '../libs/I18n';
import Stream from '../libs/Stream';
import routeStream from './routeStream';
import constants from '../internals/constants';
import config from '../config';


const CS_CZ = constants.CS_CZ;
const SK_SK = constants.SK_SK;
const DE_DE = constants.DE_DE;
const DE_AT = constants.DE_AT;
const CS = constants.CS;
const SK = constants.SK;
const DE = constants.DE;

export interface LanguageStreamValue {
  previous: string | null;
  current: string | null;
}

// init i18n
let i18n = new I18n();

(global as any).i18n = i18n;

let origin = CS;
let locale = CS_CZ;
let currency = 'CZK';

if (global.location.origin.indexOf('.sk') >= 0) {
  origin = SK;
  locale = SK_SK;
  currency = 'EUR';
}

if (global.location.origin.indexOf('.de') >= 0 || global.location.origin.indexOf('.eu') >= 0) {
  origin = DE;
  locale = DE_DE;
  currency = 'EUR';
}

if (global.location.origin.indexOf('.at') >= 0) {
  origin = DE;
  locale = DE_AT;
  currency = 'EUR';
}

moment.locale(origin);
i18n.use({
  strings: config.i18nStrings,
  locale,
  currency,
});

let languageStram: Stream<LanguageStreamValue> = new Stream({
  previous: null,
  current: null
});

(global as any).languageStram = languageStram;

languageStram.combine((changed, self, dependency) => {
  let value = self.value;
  let {language} = dependency.value;

  if (language !== value.current && (language === CS_CZ || language === SK_SK || language === DE_DE || language === DE_AT)) {
    let newValue = {
      current: language,
      previous: value.current
    };

    if (language === CS_CZ) {
      moment.locale(CS);
      i18n.use({
        strings: config.i18nStrings,
        locale: CS_CZ,
        currency: 'CZK'
      });
    } else if (language === SK_SK) {
      moment.locale(SK);
      i18n.use({
        strings: config.i18nStrings,
        locale: SK_SK,
        currency: 'EUR'
      });
    } else if (language === DE_DE) {
      moment.locale(DE);
      i18n.use({
        strings: config.i18nStrings,
        locale: DE_DE,
        currency: 'EUR'
      });
    } else if (language === DE_AT) {
      moment.locale(DE);
      i18n.use({
        strings: config.i18nStrings,
        locale: DE_DE, // use DE_DE for DE_AT
        currency: 'EUR'
      });
    }

    self.push(newValue);
  }
}, [routeStream]);

export default languageStram;
