import React, {memo} from 'react';

import styles from './Footer.css';
import I18n from '../libs/I18n';
import languageStream from '../streams/languageStream';
import {useStream} from '../hooks/useStream';

let i18n = new I18n();

export let Footer = memo(() => {
  useStream(languageStream);

  let baseUrl = 'okna-hned.cz';
  let copyrightText = '© 2022 Okna-hned.cz';
  let phoneNumber = '+420 530 332 552';

  if (languageStream.value.current === 'sk-SK') {
    copyrightText = '© 2022 Okna-hned.sk';
  }

  if (languageStream.value.current === 'de-DE' || languageStream.value.current === 'de-AT') {
    copyrightText = '© 2022 Fenster jetzt';
  }

  if (languageStream.value.current === 'sk-SK') {
    baseUrl = 'okna-hned.sk';
    phoneNumber = '+421 233 418 611';
  }

  if (languageStream.value.current === 'de-DE') {
    baseUrl = 'fenster-jetzt.de';
    phoneNumber = '+43 720 775 180';
  }

  if (languageStream.value.current === 'de-AT') {
    baseUrl = 'fenster-jetzt.at';
    phoneNumber = '+43 720 775 180';
  }

  return (
    <footer className={styles.root}>
      <p className={styles.copyright}>{copyrightText}</p>
      
      {languageStream.value.current === 'de-DE' || languageStream.value.current === 'de-AT' ? (
        <ul className={styles.contact}>
          {phoneNumber ? <li>{phoneNumber}</li> : null}
          <li>
            {languageStream.value.current === 'de-DE' ? (
              <a href="mailto:info.de@fenster-jetzt.de">info.de@fenster-jetzt.de</a>
            ) : (
              <a href="mailto:info.at@fenster-jetzt.at">info.at@fenster-jetzt.at</a>
            )}
          </li>
        </ul>
      ) : (
        <ul className={styles.contact}>
          <li>{phoneNumber}</li>
          <li>
            {languageStream.value.current === 'sk-SK' ? (
              <a href="mailto:info.sk@okna-hned.sk">info.sk@okna-hned.sk</a>
            ) : (
              <a href="mailto:info@okna-hned.cz">info@okna-hned.cz</a>
            )}
          </li>
        </ul>
      )}

      <nav className={styles.links}>
        <ul>
          <li>
            <a
              href={`https://www.${baseUrl}/content/4-o-firme-plastova-okna`}
              target="_blank"
            >{i18n.translate`Footer - links - about us`}</a>
          </li>
          <li>
            <a
              href={`https://www.${baseUrl}/content/3-obchodni-podminky-okna-hned`}
              target="_blank"
            >{i18n.translate`Footer - links - terms`}</a>
          </li>
          <li>
            <a
              href={`https://www.${baseUrl}/content/1-dodani-plastovych-oken-dveri`}
              target="_blank"
            >{i18n.translate`Footer - links - delivery`}</a>
          </li>
          <li>
            <a href={`https://www.${baseUrl}`} target="_blank">{i18n.translate`Footer - links - stored`}</a>
          </li>
        </ul>
      </nav>
    </footer>
  );
});
