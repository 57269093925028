import _ from 'lodash';

import config from '../config';

const ONE_PLN_IN_CZK = config.ONE_PLN_IN_CZK;
const MARGIN = config.MARGIN;
const WEIGHT_OF_M2_IN_KG = config.WEIGHT_OF_M2_IN_KG;
const MM_IN_M = 1000;
const MM_IN_CM = 10;

export default function getOrderItemPrice(orderItem) {
  let price = 0;
  let totalPrice = 0;
  let windowPrice = 0;
  let accessoriesPrice = 0;
  let externalAccessoriesPrice = 0;
  let weight = 0;
  let totalWeight = 0;

  if (!(orderItem.window && orderItem.material && orderItem.glass && orderItem.color)) {
    return {price, totalPrice, windowPrice, accessoriesPrice, externalAccessoriesPrice};
  }

  let colorPriceModifier = 1;
  let materialPriceModifier = 1;

  if (_.isFinite(orderItem.window.materialId)) {
    materialPriceModifier = 1;
  } else {
    materialPriceModifier = orderItem.material.priceModifier;
  }

  orderItem.color.priceModifiers.forEach((priceModifier) => {
    if (
      priceModifier.materialIds.includes(orderItem.material.id) &&
      priceModifier.sidesCounts.includes(orderItem.color.sidesCount)
    ) {
      colorPriceModifier = priceModifier.value;
    }
  });

  windowPrice =
    (orderItem.window.price * config.COEFFICIENT * materialPriceModifier * colorPriceModifier +
      (orderItem.window.width / MM_IN_M) * (orderItem.window.height / MM_IN_M) * orderItem.glass.price) *
    orderItem.material.rebate *
    MARGIN *
    ONE_PLN_IN_CZK;
  
  // console.log(orderItem.window.price, config.COEFFICIENT, materialPriceModifier, colorPriceModifier, orderItem.window.width / MM_IN_M, orderItem.window.height / MM_IN_M, orderItem.glass.price, orderItem.material.rebate, MARGIN, ONE_PLN_IN_CZK);

  if (orderItem.sunblind && _.isFinite(orderItem.sunblind.price)) {
    accessoriesPrice += orderItem.sunblind.price;
  }

  if (
    orderItem.innerWindowsill &&
    _.isFinite(orderItem.innerWindowsill.price) &&
    _.isFinite(orderItem.innerWindowsill.width)
  ) {
    accessoriesPrice += (orderItem.innerWindowsill.price * orderItem.innerWindowsill.width) / MM_IN_CM;
  }

  if (
    orderItem.outerWindowsill &&
    _.isFinite(orderItem.outerWindowsill.price) &&
    _.isFinite(orderItem.outerWindowsill.width)
  ) {
    accessoriesPrice += (orderItem.outerWindowsill.price * orderItem.outerWindowsill.width) / MM_IN_CM;
  }

  if (orderItem.accessories) {
    orderItem.accessories.forEach((accessory) => {
      if (_.isFinite(accessory.price)) {
        externalAccessoriesPrice += accessory.price * accessory.count;
      }
    });
  }

  price = windowPrice + accessoriesPrice + externalAccessoriesPrice;
  totalPrice = price * orderItem.count;
  weight = (orderItem.window.width / MM_IN_M) * (orderItem.window.height / MM_IN_M) * WEIGHT_OF_M2_IN_KG;
  totalWeight = weight * orderItem.count;

  return {price, totalPrice, windowPrice, accessoriesPrice, externalAccessoriesPrice, weight, totalWeight};
}
