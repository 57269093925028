import React from 'react';

import './Page.css';
import {Header} from './Header';
import {Main} from './Main';
import {Footer} from './Footer';
import styles from './App.css';

export let App = () => {
  return (
    <div className={styles.root}>
      <Header />
      <Main />
      <Footer />
    </div>
  );
};
