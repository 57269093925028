import Stream from '../libs/Stream';


export interface SelectMaterialAction {
	id: number;
	name: string;
	priceModifier: number;
	rebate: number;
}

let selectMaterialActionStream: Stream<SelectMaterialAction> = new Stream() as Stream<SelectMaterialAction>;

export default selectMaterialActionStream;
