import _ from 'lodash';

import {Material, Materials, WindowType} from '../types/data';


export default function findDefaultMaterial(materials: Materials, windowType?: WindowType): Material {
	if (windowType && _.isFinite(windowType.materialId)) {
		let material = _.find(materials, {id: windowType.materialId});

		if (material) {
			return _.cloneDeep(material);
		}
		
		return _.cloneDeep(materials[0]);
	}

	return _.cloneDeep(materials[0]);
}
