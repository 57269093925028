import {Color, Colors} from '../types/data';

export default function findDefaultColor(colors: Colors): any {
  return {
    id: colors[0].id,
    name: colors[0].name,
    category: colors[0].category,
    sidesCount: 2,
    priceModifiers: colors[0].priceModifiers,
  };
}
