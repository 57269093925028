/* eslint-disable complexity */

import _ from 'lodash';
import React from 'react';

import styles from './OrderStep.css';
import Button from './Button';
import Input from './Input';
import WindowVisualization from './WindowVisualization';
import ButtonGroup from './ButtonGroup';
import dataStream from '../streams/dataStream';
import orderStream from '../streams/orderStream';
import Stream from '../libs/Stream';
import {OrderItem} from '../types/structure';
import openStepActionStream from '../streams/openStepActionStream';
import I18n from '../libs/I18n';
import languageStream from '../streams/languageStream';
import Icon from './Icon';
import destroyOrderItemActionStream from '../streams/destroyOrderItemActionStream';
import selectOrderItemActionStream from '../streams/selectOrderItemActionStream';
import increaseOrderItemCountActionStream from '../streams/increaseOrderItemCountActionStream';
import decreaseOrderItemCountActionStream from '../streams/decreaseOrderItemCountActionStream';
import updatePhoneActionStream from '../streams/updatePhoneActionStream';
import getConvertedPrice from '../utils/getConvertedPrice';
import link from '../utils/link';
import uuid4 from '../internals/uuid4';
import config from '../config';

const ALT_NAME_HEIGHT = config.ALT_NAME_HEIGHT;
const VAT_MODIFIER = config.VAT_MODIFIER;

let i18n = new I18n();

export interface OrderItemLinkProps {
  index: number;
  orderItem: OrderItem;
  handleShow: (index: number) => void;
  handleAdd: (index: number) => void;
  handleRemove: (index: number) => void;
  handleDelete: (index: number) => void;
}

export class OrderItemLink extends React.Component<OrderItemLinkProps, {}> {
  onLanguageStream: Stream<{}>;

  componentDidMount() {
    this.onLanguageStream = languageStream.on(() => {
      // requestAnimationFrame(() => this.forceUpdate());
    });
  }

  componentWillUnmount() {
    this.onLanguageStream.end.push(true);
  }

  render() {
    if (!this.props) {
      return null;
    }

    let order = orderStream.value;

    return (
      <div className={styles.orderItemLink + (this.props.index === order.currentItem ? ` ${styles.isSelected}` : '')}>
        {this.props.index === order.currentItem ? (
          <span className={styles.orderItemLinkIcon}>
            <Icon id="cog" />
          </span>
        ) : null}
        <span className={styles.orderItemName}>{i18n.translate`OrderStep - order item link - heading - ${this.props
          .index + 1}`}</span>
        <span className={styles.orderItemLinks}>
          <a href="#" onClick={this.handleAddClick}>{i18n.translate`OrderStep - order item link - button 1`}</a>
          <a href="#" onClick={this.handleRemoveClick}>{i18n.translate`OrderStep - order item link - button 2`}</a>
          <a href="#" onClick={this.handleDeleteClick}>{i18n.translate`OrderStep - order item link - button 3`}</a>
          <a href="#" onClick={this.handleShowClick}>{i18n.translate`OrderStep - order item link - button 4`}</a>
        </span>
      </div>
    );
  }

  handleShowClick = (event) => {
    event.preventDefault();

    if (this.props && this.props.handleShow) {
      this.props.handleShow(this.props.index);
    }
  };

  handleAddClick = (event) => {
    event.preventDefault();

    if (this.props && this.props.handleAdd) {
      this.props.handleAdd(this.props.index);
    }
  };

  handleRemoveClick = (event) => {
    event.preventDefault();

    if (this.props && this.props.handleRemove) {
      this.props.handleRemove(this.props.index);
    }
  };

  handleDeleteClick = (event) => {
    event.preventDefault();

    if (this.props && this.props.handleDelete) {
      this.props.handleDelete(this.props.index);
    }
  };
}

export interface OrderItemDetailProps {
  orderItem: OrderItem;
  index: number;
}

export class OrderItemDetail extends React.Component<OrderItemDetailProps, {}> {
  render() {
    if (!this.props) {
      return null;
    }

    let {windowTypes} = dataStream.value;
    let orderItem = this.props.orderItem;
    let windowType;

    if (orderItem.window) {
      windowType = _.find(windowTypes, {id: orderItem.window.typeId});
    }

    return (
      <div className={styles.orderItemDetail}>
        <h4 className={styles.orderItemDetailHeading}>
          <OrderItemLink
            index={this.props.index}
            orderItem={orderItem}
            handleShow={this.handleShowOrderItem}
            handleAdd={this.handleAddOrderItem}
            handleRemove={this.handleRemoveOrderItem}
            handleDelete={this.handleDeleteOrderItem}
          />
        </h4>

        <ul className={styles.orderItemDetailProperties}>
          <li key="1" className={`${styles.orderItemDetailProperty} ${styles.isHiddenPrinted}`}>
            <span
              className={styles.orderItemDetailPropertyLabel}
            >{i18n.translate`OrderStep - order item detail - property label 1`}</span>
            <span
              className={styles.orderItemDetailPropertyValue}
            >{i18n.translate`OrderStep - order item detail - property value - ${getConvertedPrice(
              orderItem.price,
            )}:c`}</span>
          </li>

          {orderItem.window && windowType ? (
            <li key="2" className={styles.orderItemDetailProperty}>
              <span
                className={styles.orderItemDetailPropertyLabel}
              >{i18n.translate`OrderStep - order item detail - property label 2`}</span>
              <span className={styles.orderItemDetailPropertyValue}>
                <span className={styles.orderItemDetailPropertySubvalue}>
                  {windowType.altName && orderItem.window.height >= ALT_NAME_HEIGHT
                    ? windowType.altName[languageStream.value.current]
                    : windowType.name[languageStream.value.current]}
                </span>
                <span className={styles.orderItemDetailPropertySubvalue}>
                  {orderItem.window.width} × {orderItem.window.height} mm
                </span>
                {windowType.hasLeftRightVariants ? (
                  <span className={styles.orderItemDetailPropertySubvalue}>
                    {i18n.translate`windowType - variant`}:{' '}
                    {orderItem.window.variant === 1
                      ? i18n.translate`windowType - variant - left`
                      : i18n.translate`windowType - variant - right`}
                  </span>
                ) : null}
              </span>
            </li>
          ) : null}

          {orderItem.material ? (
            // HACK - altName material (TROCAL) 
            <li key="3" className={`${styles.orderItemDetailProperty} ${styles.isHighlightedPrinted}`}>
              <span
                className={styles.orderItemDetailPropertyLabel}
              >{i18n.translate`OrderStep - order item detail - property label 3`}</span>
              
              <span className={styles.orderItemDetailPropertyValue}>
                {orderItem.material.id === 1 && (windowType.id === 13 || windowType.id === 16)
                      ? orderItem.material.altName[languageStream.value.current] // i18n.getTranslationString('TROCAL 76')
                      : orderItem.material.name[languageStream.value.current]}
              </span>
            </li>
          ) : null}

          {orderItem.glass ? (
            <li key="4" className={`${styles.orderItemDetailProperty} ${styles.isHighlightedPrinted}`}>
              <span
                className={styles.orderItemDetailPropertyLabel}
              >{i18n.translate`OrderStep - order item detail - property label 4`}</span>
              <span className={styles.orderItemDetailPropertyValue}>
                {orderItem.glass.name[languageStream.value.current]}
              </span>
            </li>
          ) : null}

          {orderItem.color ? (
            <li key="5" className={`${styles.orderItemDetailProperty} ${styles.isHighlightedPrinted}`}>
              <span
                className={styles.orderItemDetailPropertyLabel}
              >{i18n.translate`OrderStep - order item detail - property label 5`}</span>
              <span className={styles.orderItemDetailPropertyValue}>
                {orderItem.color.name[languageStream.value.current]} (
                {orderItem.color.sidesCount > 1 ? i18n.translate`color - two sides` : i18n.translate`color - one side`})
              </span>
            </li>
          ) : null}

          {orderItem.sunblind ? (
            <li key="6" className={`${styles.orderItemDetailProperty} ${styles.isHighlightedPrinted}`}>
              <span
                className={styles.orderItemDetailPropertyLabel}
              >{i18n.translate`OrderStep - order item detail - property label 9`}</span>
              <span className={styles.orderItemDetailPropertyValue}>
                {orderItem.sunblind.color[languageStream.value.current]}
              </span>
            </li>
          ) : null}

          {orderItem.innerWindowsill ? (
            <li key="7" className={`${styles.orderItemDetailProperty} ${styles.isHighlightedPrinted}`}>
              <span
                className={styles.orderItemDetailPropertyLabel}
              >{i18n.translate`OrderStep - order item detail - property label 10`}</span>
              <span className={styles.orderItemDetailPropertyValue}>
                {orderItem.innerWindowsill.color?.[languageStream.value.current] ?? null}{' '}
                {` ${orderItem.innerWindowsill.width} mm × ${orderItem.innerWindowsill.depth} mm`}
              </span>
            </li>
          ) : null}

          {orderItem.outerWindowsill ? (
            <li key="8" className={`${styles.orderItemDetailProperty} ${styles.isHighlightedPrinted}`}>
              <span
                className={styles.orderItemDetailPropertyLabel}
              >{i18n.translate`OrderStep - order item detail - property label 11`}</span>
              <span className={styles.orderItemDetailPropertyValue}>
                {orderItem.outerWindowsill.color?.[languageStream.value.current] ?? null}{' '}
                {` ${orderItem.outerWindowsill.width} mm × ${orderItem.outerWindowsill.depth} mm`}
              </span>
            </li>
          ) : null}

          {orderItem.accessories && orderItem.accessories.length ? (
            <li key="9" className={`${styles.orderItemDetailProperty} ${styles.isHighlightedPrinted}`}>
              <span
                className={styles.orderItemDetailPropertyLabel}
              >{i18n.translate`OrderStep - order item detail - property label 12`}</span>
              <span className={styles.orderItemDetailPropertyValue}>
                {orderItem.accessories.map((accessory, index) => (
                  <span key={index} className={styles.orderItemDetailPropertySubvalue}>
                    {accessory.name[languageStream.value.current]}
                  </span>
                ))}
              </span>
            </li>
          ) : null}

          {orderItem.glass ? (
            <li key="10" className={styles.orderItemDetailProperty}>
              <span
                className={styles.orderItemDetailPropertyHighlightedLabel}
              >{i18n.translate`OrderStep - order item detail - property label 7`}</span>
              <span className={styles.orderItemDetailPropertyHighlightedValue}>{orderItem.count}</span>
            </li>
          ) : null}

          {orderItem.glass ? (
            <li key="11" className={`${styles.orderItemDetailProperty} ${styles.isHiddenPrinted}`}>
              <span
                className={styles.orderItemDetailPropertyLabel}
              >{i18n.translate`OrderStep - order item detail - property label 8`}</span>
              <span
                className={styles.orderItemDetailPropertyValue}
              >{i18n.translate`OrderStep - order item detail - property value - ${getConvertedPrice(
                orderItem.totalPrice,
              )}:c`}</span>
            </li>
          ) : null}

          {orderItem.glass ? (
            <li key="12" className={`${styles.orderItemDetailProperty} ${styles.isHiddenPrinted}`}>
              <span
                className={styles.orderItemDetailPropertyPriceLabel}
              >{i18n.translate`OrderStep - order item detail - property label 13`}</span>
              <span
                className={styles.orderItemDetailPropertyPriceValue}
              >{i18n.translate`OrderStep - order item detail - property value - ${getConvertedPrice(
                orderItem.totalPrice * VAT_MODIFIER[languageStream.value.current],
              )}:c`}</span>
            </li>
          ) : null}
        </ul>

        <div className={styles.orderItemDetailVisualization}>
          <WindowVisualization orderItem={orderItem} />
        </div>
      </div>
    );
  }

  handleDeleteOrderItem = () => {
    if (!this.props) {
      return;
    }

    destroyOrderItemActionStream.push(this.props.index);
  };

  handleShowOrderItem = () => {
    if (!this.props) {
      return;
    }

    selectOrderItemActionStream.push(this.props.index);
  };

  handleAddOrderItem = () => {
    if (!this.props) {
      return;
    }

    increaseOrderItemCountActionStream.push(this.props.index);
  };

  handleRemoveOrderItem = () => {
    if (!this.props) {
      return;
    }

    decreaseOrderItemCountActionStream.push(this.props.index);
  };
}

export interface OrderStepProps {
  isOpen: boolean;
}

export interface OrderStepState {
  width: number;
  height: number;
  areDimensionsSaved: boolean;
}

export default class OrderStep extends React.Component<OrderStepProps, OrderStepState> {
  onLanguageStream: Stream<{}>;
  onOrderStream: Stream<{}>;

  componentDidMount() {
    this.onLanguageStream = languageStream.on(() => {
      // console.log('OrderStep 1');
      // requestAnimationFrame(() => {
      //   console.log('OrderStep 2');
      this.forceUpdate();
      // });
    });
    this.onOrderStream = orderStream.on(() => {
      // requestAnimationFrame(() => this.forceUpdate());
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.onLanguageStream.end.push(true);
    this.onOrderStream.end.push(true);
  }

  render() {
    let order = orderStream.value;

    return (
      <section className={styles.root + (this.props && this.props.isOpen ? ` ${styles.isOpen}` : '')}>
        <div className={styles.wrapper}>
          <h1 className={styles.heading}>{i18n.translate`OrderStep - heading 1`}</h1>
          <span className={styles.closeButton}>
            <Button type="invisible" size="large" icon="cross" handleClick={this.handleCloseClick} />
          </span>

          <div>
            {order.items.length ? (
              <div className={styles.orderItemsList}>
                {order.items.map((orderItem, index) => (
                  <OrderItemDetail key={index} orderItem={orderItem} index={index} />
                ))}
              </div>
            ) : null}

            <div className={styles.input}>
              <label htmlFor="phone">{i18n.translate`OrderStep - phone`}</label>
              <Input type="text" name="phone" value={order.phone} handleChange={this.handlePhoneChange} />
            </div>

            <ButtonGroup align="center">
              <Button
                type="flat"
                size="large"
                label={i18n.translate`OrderStep - button 1`}
                handleClick={this.handleCloseClick}
              />
              <Button
                type="flat"
                size="large"
                label={i18n.translate`OrderStep - button 2`}
                handleClick={this.handleFinishClick}
              />
            </ButtonGroup>
          </div>
        </div>
      </section>
    );
  }

  handleCloseClick = () => {
    openStepActionStream.push(null);
  };

  handlePhoneChange = (value) => {
    if (value || value === '') {
      updatePhoneActionStream.push(value);
    }
  };

  handleFinishClick = () => {
    let order = _.cloneDeep(orderStream.value);
    let items: Array<any> = [];

    for (let i = 0; i < order.items.length; i++) {
      if (order.items[i].price > 0) {
        items.push(order.items[i]);
      }
    }

    if (items.length) {
      // check items - in case of TROCAL door (windowType ids 13 and 16, materialId == 1) - replace name with altName (HACK)
      _.forEach(items, (item, index, collection) => {
        if ((item.window?.typeId === 13 || item.window?.typeId === 16) && item.window.materialId === 1 && item.material.altName) {
          item.material.name = item.material.altName;
        }
      });

      order.items = items;
      order.uuid = orderStream.value.uuid = uuid4();
      order.phone = orderStream.value.phone = orderStream.value.phone || '';

      let serializedOrder = Object.assign({}, order);
      let openedWindow = window.open(link('importOrder.html'), '_blank');
      let wasImported = false;

      console.log('serializedOrder', serializedOrder);

      openedWindow.focus();

      if (openedWindow.addEventListener) {
        openedWindow.addEventListener(
          'load',
          () => {
            (openedWindow as any).importOrder(serializedOrder);

            wasImported = true;
          },
          true,
        );
      } else {
        openedWindow.onload = () => {
          (openedWindow as any).importOrder(serializedOrder);

          wasImported = true;
        };
      }

      setTimeout(() => {
        if (!wasImported) {
          (openedWindow as any).importOrder(serializedOrder);
        }
      }, 4000);
    }
  };

  handleDeleteOrderItem = (index) => {
    destroyOrderItemActionStream.push(index);
  };

  handleShowOrderItem = (index) => {
    selectOrderItemActionStream.push(index);
  };

  handleAddOrderItem = (index) => {
    increaseOrderItemCountActionStream.push(index);
  };

  handleRemoveOrderItem = (index) => {
    decreaseOrderItemCountActionStream.push(index);
  };
}
