import _ from 'lodash';

import windowTypes from './windowTypes.json';
import windowSizes from './windowSizes.json';
import windowRecommendations from './windowRecommendations.json';
import materials from './materials.json';
import glasses from './glasses.json';
import colors from './colors.json';
import sunblindSizes from './sunblindSizes.json';
import sunblindColors from './sunblindColors.json';
import innerWindowsillSizes from './innerWindowsillSizes.json';
import innerWindowsillColors from './innerWindowsillColors.json';
import outerWindowsillSizes from './outerWindowsillSizes.json';
import outerWindowsillColors from './outerWindowsillColors.json';
import {WindowTypes, WindowSizes, WindowRecommendations, Materials, Glasses, Colors, ColorCategories, SunblindSizes, SunblindColors, InnerWindowsillSizes, InnerWindowsillColors, OuterWindowsillSizes, OuterWindowsillColors} from '../types/data';

let colorCategories = colors.map((color) => ({id: color.category}));

colorCategories = _.uniqWith(colorCategories, _.isEqual);

let data: {
	windowTypes: WindowTypes;
	windowSizes: WindowSizes;
	windowRecommendations: WindowRecommendations;
	materials: Materials;
	glasses: Glasses;
	colors: Colors;
	colorCategories: ColorCategories;
	sunblindSizes: SunblindSizes;
	sunblindColors: SunblindColors;
	innerWindowsillSizes: InnerWindowsillSizes;
	innerWindowsillColors: InnerWindowsillColors;
	outerWindowsillSizes: OuterWindowsillSizes;
	outerWindowsillColors: OuterWindowsillColors;
} = {windowTypes, windowSizes, windowRecommendations, materials, glasses, colors, colorCategories, sunblindSizes, sunblindColors, innerWindowsillSizes, innerWindowsillColors, outerWindowsillSizes, outerWindowsillColors};

export default data;
