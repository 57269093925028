/* eslint-disable complexity */

import $ from 'jquery';
import _ from 'lodash';
import Bluebird from 'bluebird';

import createOrderItemActionStream from './createOrderItemActionStream';
import selectOrderItemActionStream from './selectOrderItemActionStream';
import increaseOrderItemCountActionStream from './increaseOrderItemCountActionStream';
import decreaseOrderItemCountActionStream from './decreaseOrderItemCountActionStream';
import destroyOrderItemActionStream from './destroyOrderItemActionStream';
import dataStream from './dataStream';
import selectWindowActionStream from './selectWindowActionStream';
import selectMaterialActionStream from './selectMaterialActionStream';
import selectGlassActionStream from './selectGlassActionStream';
import selectColorActionStream from './selectColorActionStream';
import selectAccessoriesActionStream from './selectAccessoriesActionStream';
import updatePhoneActionStream from './updatePhoneActionStream';
import Stream from '../libs/Stream';
import {Order} from '../types/structure';
import setOrderItemPrice from '../utils/setOrderItemPrice';
import findDefaultGlass from '../utils/findDefaultGlass';
import findDefaultMaterial from '../utils/findDefaultMaterial';
import findDefaultColor from '../utils/findDefaultColor';
import languageStream from './languageStream';
import routeStream from './routeStream';
import BrowserRouter from '../libs/BrowserRouter';
import router from '../streams/router';
import link from '../utils/link';
import uuid4 from '../internals/uuid4';
import constants from '../internals/constants';
import openStepActionStream from './openStepActionStream';

const UUID_REGEXP = /[0-9A-Za-z]{8}-[0-9A-Za-z]{4}-4[0-9A-Za-z]{3}-[89ABab][0-9A-Za-z]{3}-[0-9A-Za-z]{12}/;
const CS_CZ = constants.CS_CZ;
const SK_SK = constants.SK_SK;
const DE_DE = constants.DE_DE;
const DE_AT = constants.DE_AT;

let browserRouter = new BrowserRouter(router);

function deserializeOrder(serializedOrder) {
  if (!serializedOrder) {
    return null;
  }

  let order = null;

  try {
    order = JSON.parse(decodeURIComponent(atob(serializedOrder)));
  } catch (error) {
    return null;
  }

  if (!order.uuid) {
    order.uuid = uuid4();
  }

  if (!order.items.length) {
    order.currentItem = null;
  }

  if (order.items.length && !order.currentItem) {
    order.currentItem = 0;

    for (let i = 0; i < order.items.length; i++) {
      if (!order.items[i].count) {
        order.items[i].count = 1;
      }
    }
  }

  if (!order.phone) {
    order.phone = '';
  }

  if (order.language !== CS_CZ && order.language !== SK_SK && order.language !== DE_DE && order.language !== DE_AT) {
    order.language = languageStream.value.current;
  }

  openStepActionStream.push('window');

  return order;
}

let orderStream: Stream<Order> = new Stream({
  uuid: uuid4(),
  items: [],
  currentItem: null,
  language: null,
  phone: '',
});

(global as any).orderStream = orderStream;

orderStream
  .combine(
    async (changed, self) => {
      let order = self.value;
      let isOrderUpdated = false;

      if (changed.includes(languageStream)) {
        order.language = languageStream.value.current;

        isOrderUpdated = true;
      }

      if (changed.includes(routeStream)) {
        let response;

        if (UUID_REGEXP.test(routeStream.value.order || '')) {
          try {
            response = await Bluebird.resolve(
              $.ajax({
                url: 'https://www.okna-hned.cz/scripts/getInfo.php',
                type: 'POST',
                data: {
                  data: routeStream.value.order,
                },
              }),
            );
          } catch (error) {}

          if (response) {
            try {
              order = JSON.parse(response);

              let {
                materials,
                glasses,
                colors,
                sunblindColors,
                innerWindowsillColors,
                outerWindowsillColors,
              } = dataStream.value;

              if (order.items && order.items.length) {
                for (let i = 0; i < order.items.length; i++) {
                  let orderItem = order.items[i];

                  if (typeof orderItem.color?.name === 'string') {
                    let correctColor = _.find(colors, {id: orderItem.color.id});

                    if (correctColor) {
                      orderItem.color.name = correctColor.name;
                    }
                  }

                  if (typeof orderItem.glass?.name === 'string') {
                    let correctGlass = _.find(glasses, {id: orderItem.glass.id});

                    if (correctGlass) {
                      orderItem.glass.name = correctGlass.name;
                    }
                  }

                  if (typeof orderItem.material?.name === 'string') {
                    let correctMaterial = _.find(materials, {id: orderItem.material.id});

                    if (correctMaterial) {
                      orderItem.material.name = correctMaterial.name;
                      orderItem.material.altName = correctMaterial.altName;
                      orderItem.material.title = correctMaterial.title;
                      orderItem.material.description1 = correctMaterial.description1;
                      orderItem.material.description2 = correctMaterial.description2;
                    }
                  }

                  if (typeof orderItem.sunblind?.color === 'string') {
                    let correctSunblindColor = _.find(sunblindColors, {name: {[CS_CZ]: orderItem.sunblind.color}});

                    if (correctSunblindColor) {
                      orderItem.sunblind.color = correctSunblindColor.name;
                    } else {
                      orderItem.sunblind.color = {
                        [CS_CZ]: orderItem.sunblind.color,
                        [SK_SK]: orderItem.sunblind.color,
                        [DE_DE]: orderItem.sunblind.color,
                        [DE_AT]: orderItem.sunblind.color,
                      };
                    }
                  }

                  if (typeof orderItem.innerWindowsill?.color === 'string') {
                    let correctInnerWindowsillColor = _.find(innerWindowsillColors, {
                      name: {[CS_CZ]: orderItem.innerWindowsill.color},
                    });

                    if (correctInnerWindowsillColor) {
                      orderItem.innerWindowsill.color = correctInnerWindowsillColor.name;
                    } else {
                      orderItem.innerWindowsill.color = {
                        [CS_CZ]: orderItem.innerWindowsill.color,
                        [SK_SK]: orderItem.innerWindowsill.color,
                        [DE_DE]: orderItem.innerWindowsill.color,
                        [DE_AT]: orderItem.innerWindowsill.color,
                      };
                    }
                  }

                  if (typeof orderItem.outerWindowsill?.color === 'string') {
                    let correctOuterWindowsillColor = _.find(outerWindowsillColors, {
                      name: {[CS_CZ]: orderItem.outerWindowsill.color},
                    });

                    if (correctOuterWindowsillColor) {
                      orderItem.outerWindowsill.color = correctOuterWindowsillColor.name;
                    } else {
                      orderItem.outerWindowsill.color = {
                        [CS_CZ]: orderItem.outerWindowsill.color,
                        [SK_SK]: orderItem.outerWindowsill.color,
                        [DE_DE]: orderItem.outerWindowsill.color,
                        [DE_AT]: orderItem.outerWindowsill.color,
                      };
                    }
                  }
                }
              }

              isOrderUpdated = true;
            } catch (error) {
              console.warn(error);
            }
          }
        } else {
          let newOrder = deserializeOrder(response || routeStream.value.order);

          if (newOrder) {
            order = newOrder;
          }

          isOrderUpdated = true;
        }
      }

      if (changed.includes(createOrderItemActionStream)) {
        order.items.push({
          count: 1,
          price: 0,
          totalPrice: 0,
          windowPrice: 0,
          accessoriesPrice: 0,
          externalAccessoriesPrice: 0,
          weight: 0,
          totalWeight: 0,
        });

        order.currentItem = order.items.length - 1;
        isOrderUpdated = true;
      }

      if (changed.includes(destroyOrderItemActionStream)) {
        let index = destroyOrderItemActionStream.value;

        if (index >= 0 && index <= order.items.length - 1) {
          order.items.splice(index, 1);

          if (order.currentItem !== null && index >= order.currentItem) {
            order.currentItem--;

            if (order.currentItem < 0) {
              order.currentItem = 0;
            }
          }

          if (order.currentItem > order.items.length - 1) {
            order.currentItem--;
          }

          if (!order.items.length) {
            order.currentItem = null;
          }

          self.push({...order});
        }
      } else if (changed.includes(updatePhoneActionStream)) {
        order.phone = updatePhoneActionStream.value;

        self.push({...order});
      } else if (changed.includes(selectOrderItemActionStream)) {
        let index = selectOrderItemActionStream.value;

        if (index >= 0 && index <= order.items.length - 1) {
          order.currentItem = index;

          // update price
          if (order.currentItem !== null) {
            setOrderItemPrice(order.items[order.currentItem]);
          }

          self.push({...order});
        }
      } else if (changed.includes(increaseOrderItemCountActionStream)) {
        let index = increaseOrderItemCountActionStream.value;

        if (index >= 0 && index <= order.items.length - 1) {
          order.items[index].count++;

          // update price
          if (order.currentItem !== null) {
            setOrderItemPrice(order.items[order.currentItem]);
          }

          self.push({...order});
        }
      } else if (changed.includes(decreaseOrderItemCountActionStream)) {
        let index = decreaseOrderItemCountActionStream.value;

        if (index >= 0 && index <= order.items.length - 1 && order.items[index].count >= 2) {
          order.items[index].count--;

          // update price
          if (order.currentItem !== null) {
            setOrderItemPrice(order.items[order.currentItem]);
          }

          self.push({...order});
        }
      } else if (changed.length) {
        let orderItem = order.currentItem === null ? undefined : order.items[order.currentItem];
        let {windowTypes, materials, glasses, colors} = dataStream.value;

        if (orderItem && changed.includes(selectWindowActionStream)) {
          orderItem.window = selectWindowActionStream.value;

          let windowType = _.find(windowTypes, {id: orderItem.window.typeId});

          if (windowType && windowType.materialId) {
            orderItem.material = findDefaultMaterial(materials, windowType);
            orderItem.glass = findDefaultGlass(glasses, orderItem.material.id);
          } else {
            orderItem.material = orderItem.material || findDefaultMaterial(materials, windowType);
            orderItem.glass = orderItem.glass || findDefaultGlass(glasses, orderItem.material.id);
          }

          orderItem.color = orderItem.color || findDefaultColor(colors);

          setOrderItemPrice(orderItem);

          self.push({...order});
        }

        if (orderItem && changed.includes(selectMaterialActionStream)) {
          let windowType = orderItem.window ? _.find(windowTypes, {id: orderItem.window.typeId}) : undefined;

          // we can change material only if window type has no predefined material
          if (!(windowType && _.isFinite(windowType.materialId))) {
            orderItem.material = selectMaterialActionStream.value;
            orderItem.glass = findDefaultGlass(glasses, orderItem.material.id);
            orderItem.color = findDefaultColor(colors);

            setOrderItemPrice(orderItem);

            self.push({...order});
          }
        }

        if (orderItem && changed.includes(selectGlassActionStream)) {
          orderItem.glass = selectGlassActionStream.value;

          setOrderItemPrice(orderItem);

          self.push({...order});
        }

        if (orderItem && changed.includes(selectColorActionStream)) {
          orderItem.color = selectColorActionStream.value;

          setOrderItemPrice(orderItem);

          self.push({...order});
        }

        if (orderItem && changed.includes(selectAccessoriesActionStream)) {
          let selectAccessoriesAction = selectAccessoriesActionStream.value;

          if (selectAccessoriesAction.innerWindowsill) {
            orderItem.innerWindowsill = Object.assign(
              {},
              orderItem.innerWindowsill,
              selectAccessoriesAction.innerWindowsill,
            );
            
            orderItem.innerWindowsill.price = null;
            if (orderItem.innerWindowsill.prices && orderItem.innerWindowsill.priceGroup) {
              orderItem.innerWindowsill.price = orderItem.innerWindowsill.prices[orderItem.innerWindowsill.priceGroup];
            }
          } else if (selectAccessoriesAction.innerWindowsill === null) {
            orderItem.innerWindowsill = undefined;
          }

          if (selectAccessoriesAction.outerWindowsill) {
            orderItem.outerWindowsill = Object.assign(
              {},
              orderItem.outerWindowsill,
              selectAccessoriesAction.outerWindowsill,
            );

            orderItem.outerWindowsill.price = null;
            if (orderItem.outerWindowsill.prices && orderItem.outerWindowsill.priceGroup) {
              orderItem.outerWindowsill.price =
                orderItem.outerWindowsill.prices[orderItem.outerWindowsill.priceGroup - 1];
            }
          } else if (selectAccessoriesAction.outerWindowsill === null) {
            orderItem.outerWindowsill = undefined;
          }

          if (selectAccessoriesAction.sunblind) {
            orderItem.sunblind = Object.assign({}, orderItem.sunblind, selectAccessoriesAction.sunblind);
          } else if (selectAccessoriesAction.sunblind === null) {
            orderItem.sunblind = undefined;
          }

          if (selectAccessoriesAction.accessory) {
            if (orderItem.accessories) {
              let existingAccessory = _.find(orderItem.accessories, {id: selectAccessoriesAction.accessory.id});

              if (existingAccessory) {
                _.remove(orderItem.accessories, (accessory) => accessory === existingAccessory);
              } else {
                orderItem.accessories.push(selectAccessoriesAction.accessory);
              }
            } else {
              orderItem.accessories = [selectAccessoriesAction.accessory];
            }
          }

          setOrderItemPrice(orderItem);

          self.push({...order});
        }
      }

      if (process.env.NODE_ENV === 'development') {
        browserRouter.navigate(
          link(languageStream.value.current || '', 'order', btoa(encodeURIComponent(JSON.stringify(order)))),
          {trigger: false, replace: true},
        );
      }

      if (isOrderUpdated) {
        self.push({...order});
      }
    },
    [
      languageStream,
      routeStream,
      createOrderItemActionStream,
      destroyOrderItemActionStream,
      selectOrderItemActionStream,
      increaseOrderItemCountActionStream,
      decreaseOrderItemCountActionStream,
      selectWindowActionStream,
      selectMaterialActionStream,
      selectGlassActionStream,
      selectColorActionStream,
      selectAccessoriesActionStream,
      updatePhoneActionStream,
    ],
  )
  .immediate();

export default orderStream;
