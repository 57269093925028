/* eslint-disable complexity */

import React from 'react';

import styles from './Input.css';

const ENTER_KEY_CODE = 13;

export interface InputProps {
  type: 'text' | 'email' | 'number';
  id?: string;
  name?: string;
  autocomplete?: boolean;
  value?: string | number;
  min?: number;
  max?: number;
  unit?: string;
  isValid?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
  handleChange?: (value: any) => void;
  handleSave?: (value: any) => void;
  validator?: (value: any) => void;
  isUncontrolled?: boolean;
}

export default class Input extends React.Component<InputProps, {}> {
  render() {
    let props = {
      key: this.props ? this.props.id || this.props.name : '',
      className:
        styles.default +
        (this.props && this.props.isValid ? ' isValid' : '') +
        (this.props && this.props.isInvalid ? ' isInvalid' : '') +
        (this.props && this.props.isDisabled ? ' isDisabled' : ' isEnabled') +
        (this.props && this.props.unit ? ` ${styles.hasUnit}` : ''),
      type: this.props ? this.props.type : 'text',
      min: this.props && typeof this.props.min !== 'undefined' ? this.props.min : undefined,
      max: this.props && typeof this.props.max !== 'undefined' ? this.props.max : undefined,
      name: this.props ? this.props.name || this.props.id : '',
      id: this.props ? this.props.id || this.props.name : '',
      value: this.props && typeof this.props.value !== 'undefined' ? `${this.props.value}` : '',
      autoComplete: this.props ? this.props.autocomplete : false,
      disabled: this.props ? this.props.isDisabled : false,
      onBlur: this.handleFocusOut,
      onChange: this.handleInput,
      onKeyDown: this.handleKeyDown,
    };

    if (this.props.isUncontrolled) {
      delete props.value;
    }

    return this.props && typeof this.props.value !== 'undefined' ? (
      <span className={styles.root}>
        <input {...props} />
        {this.props && this.props.unit ? <span className={styles.unit}>{this.props.unit}</span> : null}
      </span>
    ) : (
      <span className={styles.root}>
        <input {...props} />
        {this.props && this.props.unit ? <span className={styles.unit}>{this.props.unit}</span> : null}
      </span>
    );
  }

  handleInput = (event) => {
    if (this.props && this.props.handleChange) {
      this.props.handleChange(this.validate(event.target.value));
    }
  };

  handleFocusOut = (event) => {
    if (this.props && this.props.handleSave) {
      this.props.handleSave(this.validate(event.target.value));
    }
  };

  handleKeyDown = (event) => {
    if (event.keyCode === ENTER_KEY_CODE) {
      if (this.props && this.props.handleSave) {
        this.props.handleSave(this.validate(event.target.value));
      }
    }
  };

  validate(value) {
    return this.props && this.props.validator ? this.props.validator(value) : value;
  }
}
