/* eslint-disable complexity */

import $ from 'jquery';
import _ from 'lodash';
import React, {memo, useCallback} from 'react';

import styles from './WindowStep.css';
import Input from './Input';
import Button from './Button';
import Icon from './Icon';
import ButtonGroup from './ButtonGroup';
import dataStream from '../streams/dataStream';
import orderStream from '../streams/orderStream';
// import Stream from '../libs/Stream';
import selectWindowActionStream from '../streams/selectWindowActionStream';
// import {WindowType, WindowSize} from '../types/data';
import selectColorActionStream from '../streams/selectColorActionStream';
import openStepActionStream from '../streams/openStepActionStream';
import I18n from '../libs/I18n';
import languageStream from '../streams/languageStream';
// import link from '../utils/link';
import getOrderItemPrice from '../utils/getOrderItemPrice';
import config from '../config';
import findDefaultGlass from '../utils/findDefaultGlass';
import findDefaultMaterial from '../utils/findDefaultMaterial';
import findDefaultColor from '../utils/findDefaultColor';
import findMaterialWithinColor from '../utils/findMaterialWithinColor';
import getConvertedPrice from '../utils/getConvertedPrice';
import {Window} from './Window';
import {useStream} from '../hooks/useStream';
import { reset } from 'nconf';

const VAT_MODIFIER = config.VAT_MODIFIER; // eslint-disable-line prefer-destructuring
const ALT_NAME_HEIGHT = config.ALT_NAME_HEIGHT; // eslint-disable-line prefer-destructuring
const NOT_DIGIT_REGEX = /\D/;

let i18n = new I18n();

/* export interface WindowDetailProps {
  variant: 1 | 2;
  width: number;
  height: number;
  windowType: WindowType;
  windowSize: WindowSize;
  isRecommended: boolean;
  isSelected: boolean;
  handleClick: (windowType: WindowType, windowSize: WindowSize, variant: number) => void;
} */

export let WindowDetail = memo(
  ({selectedVariant, width, height, windowType, windowSize, isRecommended, isSelected, handleClick}) => {
    useStream(languageStream);

    let order = useStream(orderStream);
    let orderItem;

    if (order.currentItem !== null) {
      orderItem = order.items[order.currentItem];
    }

    if (!orderItem) {
      return null;
    }

    let handleVariant1Click = useCallback(
      (event) => {
        if (handleClick && event.nativeEvent.variant2Handled !== true) {
          handleClick(windowType, windowSize, 1, orderItem.color);
        }
      },
      [windowType, windowSize, handleClick],
    );

    let handleVariant2Click = useCallback(
      (event) => {
        event.nativeEvent.variant2Handled = true;

        if (handleClick) {
          handleClick(windowType, windowSize, 2, orderItem.color);
        }
      },
      [windowType, windowSize, handleClick],
    );

    // let isSelected = orderItem && orderItem.window && orderItem.window.typeId === windowType.id;
    // let selectedVariant = orderItem && orderItem.window && orderItem.window.variant;
    let {materials, glasses, colors} = dataStream.value;
    let defaultMaterial = windowType.materialId || !orderItem.material ? findDefaultMaterial(materials, windowType) : orderItem.material;
    let defaultGlass = orderItem.glass || findDefaultGlass(glasses, defaultMaterial.id);
    let defaultColor = orderItem.color || findDefaultColor(colors);
    let tempOrderItem = _.assign(
      _.cloneDeep(orderItem),
      {
        window: {
          typeId: windowType.id,
          sizeId: windowSize.id,
          materialId: windowType.materialId,
          type: windowType.type,
          width,
          height,
          price: windowSize.price,
          variant: 1,
        },
      },
      {material: defaultMaterial},
      {glass: defaultGlass},
      {color: defaultColor},
    );
    let {windowPrice: price} = getOrderItemPrice(tempOrderItem);

    // check if material is available for the color
    let showResetColorWarning = false;

    if (orderItem.color && windowType.materialId ? !findMaterialWithinColor(orderItem.color, windowType.materialId) : false) {
      showResetColorWarning = true;
    }

    return (
      <div
        className={
          styles.windowDetail +
          (isRecommended ? ` ${styles.isRecommended}` : '') +
          (isSelected ? ` ${styles.isSelected}` : '') +
          (windowType.hasLeftRightVariants ? ` ${styles.hasLeftRightVariants}` : '')
        }
        onClick={handleVariant1Click}
      >
        <h3 className={styles.windowDetailHeading}>
          {windowType.altName && height >= ALT_NAME_HEIGHT
            ? windowType.altName[languageStream.value.current]
            : windowType.name[languageStream.value.current]}{' '}
          {width}x{height} mm
        </h3>

        <figure className={styles.windowDetailPhoto}>
          <Window
            width={orderItem.window ? orderItem.window.width : width}
            height={orderItem.window ? orderItem.window.height : height}
            color={'#ffffff'}
            type={windowType.type}
            variant={isSelected ? selectedVariant || 1 : 1}
            view="INNER"
          />
        </figure>

        {showResetColorWarning ? (
          <span className={styles.windowDetailColorWarning}>{i18n.translate`WindowStep - window not available in color`}</span>
        ) : null}

        {windowType.hasLeftRightVariants ? (
          <div className={styles.windowDetailVariants}>
            <p className={styles.windowDetailVariantsHeading}>{i18n.translate`windowType - variant`}</p>
            <p className={styles.windowDetailVariantsList}>
              <span className={styles.windowDetailVariant} onClick={handleVariant1Click}>
                <span
                  className={
                    styles.windowDetailVariantBox + (isSelected && selectedVariant === 1 ? ` ${styles.isSelected}` : '')
                  }
                >
                  <Icon id="cross" size="small" />
                </span>
                <span>{i18n.translate`windowType - variant - left`}</span>
              </span>
              <span className={styles.windowDetailVariant} onClick={handleVariant2Click}>
                <span
                  className={
                    styles.windowDetailVariantBox + (isSelected && selectedVariant === 2 ? ` ${styles.isSelected}` : '')
                  }
                >
                  <Icon id="cross" size="small" />
                </span>
                <span>{i18n.translate`windowType - variant - right`}</span>
              </span>
            </p>
          </div>
        ) : null}

        <p
          className={styles.windowDetailPrice}
        >{i18n.translate`WindowStep - window detail - price - ${getConvertedPrice(
          price * VAT_MODIFIER[languageStream.value.current],
        )}:c`}</p>

        {isRecommended ? (
          <p className={styles.windowDetailLabel}>{i18n.translate`WindowStep - window detail - recommended`}</p>
        ) : null}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      nextProps.handleClick === prevProps.handleClick &&
      nextProps.width === prevProps.width &&
      nextProps.height === prevProps.height &&
      nextProps.windowType.id === prevProps.windowType.id &&
      nextProps.windowSize.id === prevProps.windowSize.id &&
      nextProps.selectedVariant === prevProps.selectedVariant &&
      nextProps.isRecommended === prevProps.isRecommended &&
      nextProps.isSelected === prevProps.isSelected
    );
  },
);

/*export interface WindowStepProps {
  isOpen: boolean;
}

export interface WindowStepState {
  width: number;
  height: number;
  areDimensionsSaved: boolean;
}*/

export default class WindowStep extends React.Component<WindowStepProps, WindowStepState> {
  state = {
    width: 0,
    height: 0,
    areDimensionsSaved: false,
  };
  onLanguageStream: Stream<{}>;
  onOrderStream: Stream<{}>;

  constructor(props) {
    super(props);

    this.rootRef = React.createRef();
  }

  updateStateFromOrder = () => {
    let order = orderStream.value;
    let orderItem;

    if (order.currentItem !== null) {
      orderItem = order.items[order.currentItem];

      if (orderItem && orderItem.window && _.isFinite(orderItem.window.width) && _.isFinite(orderItem.window.height)) {
        if (!orderItem.window.type) {
          this.setState({
            width: orderItem.window.width,
            height: orderItem.window.height,
            areDimensionsSaved: false,
          });
        } else {
          this.setState({
            width: orderItem.window.width,
            height: orderItem.window.height,
            areDimensionsSaved: true,
          });
        }
      } else if (!orderItem || !orderItem.window) {
        this.setState({
          width: undefined,
          height: undefined,
          areDimensionsSaved: false,
        });
      }
    }
  };

  componentDidMount() {
    this.onLanguageStream = languageStream.on(() => {
      // console.log('WindowStep 1');
      // requestAnimationFrame(() => {
      //   console.log('WindowStep 1');
      this.forceUpdate();
      // });
    });
    this.onOrderStream = orderStream.on(() => {
      requestAnimationFrame(this.updateStateFromOrder);
    });

    if (orderStream.hasValue) {
      this.updateStateFromOrder();
    }
  }

  componentWillUnmount() {
    this.onLanguageStream.end.push(true);
    this.onOrderStream.end.push(true);
  }

  render() {
    let {windowTypes, windowSizes, windowRecommendations} = dataStream.value;
    let filteredWindowSizes = windowSizes.filter(
      (windowSize) =>
        this.state.width >= windowSize.widths[0] &&
        this.state.width <= windowSize.widths[1] &&
        this.state.height >= windowSize.heights[0] &&
        this.state.height <= windowSize.heights[1],
    );
    let filteredWindowRecommendations = windowRecommendations.filter(
      (windowRecommendation) =>
        this.state.width >= windowRecommendation.widths[0] &&
        this.state.width <= windowRecommendation.widths[1] &&
        this.state.height >= windowRecommendation.heights[0] &&
        this.state.height <= windowRecommendation.heights[1],
    );
    let filteredWindowTypes = filteredWindowSizes.map((windowSize) => _.find(windowTypes, {type: windowSize.type}));
    let order = orderStream.value;
    let orderItem;
    let minWidth = Math.min(...windowSizes.map((windowSize) => windowSize.widths[0]));
    let maxWidth = Math.max(...windowSizes.map((windowSize) => windowSize.widths[1]));
    let minHeight = Math.min(...windowSizes.map((windowSize) => windowSize.heights[0]));
    let maxHeight = Math.max(...windowSizes.map((windowSize) => windowSize.heights[1]));

    if (order.currentItem !== null) {
      orderItem = order.items[order.currentItem];
    }

    return (
      <section
        ref={this.rootRef}
        className={styles.root + (this.props && this.props.isOpen ? ` ${styles.isOpen}` : '')}
      >
        <div className={styles.wrapper}>
          <h1 className={styles.heading}>
            {this.state.areDimensionsSaved
              ? i18n.translate`WindowStep - heading 2`
              : i18n.translate`WindowStep - heading 1`}
          </h1>
          <span className={styles.closeButton}>
            <Button type="invisible" size="large" icon="cross" handleClick={this.handleCloseClick} />
          </span>

          {this.state.areDimensionsSaved ? (
            <div className={styles.windowType}>
              <div className={styles.windowTypeWrapper}>
                <div className={styles.windowTypesListWrapper}>
                  <p className={styles.windowTypesListScrollLeft}>
                    <Button type="floating" icon="left-arrow" handleClick={this.handleLeftScrollClick} />
                  </p>
                  <p className={styles.windowTypesListScrollRight}>
                    <Button type="floating" icon="right-arrow" handleClick={this.handleRightScrollClick} />
                  </p>
                  <ul className={styles.windowTypesList}>
                    {filteredWindowSizes.map((windowSize, windowSizeIndex) => (
                      <li key={windowSizeIndex}>
                        <WindowDetail
                          isRecommended={
                            filteredWindowRecommendations[0] &&
                            filteredWindowRecommendations[0].types.includes(windowSize.type)
                          }
                          windowType={filteredWindowTypes[windowSizeIndex]}
                          windowSize={windowSize}
                          width={this.state.width}
                          height={this.state.height}
                          handleClick={this.handleSelectWindow}
                          isSelected={
                            orderItem &&
                            orderItem.window &&
                            orderItem.window.typeId === filteredWindowTypes[windowSizeIndex].id
                          }
                          selectedVariant={orderItem && orderItem.window && orderItem.window.variant}
                        />
                      </li>
                    ))}
                  </ul>
                  <p className={styles.warning}>{i18n.translate`WindowStep - warning`}</p>
                  <p className={styles.warning}>{i18n.translate`WindowStep - info 1`}</p>
                </div>
              </div>

              <ButtonGroup align="center">
                <Button
                  type="flat"
                  size="large"
                  label={i18n.translate`WindowStep - button 2`}
                  handleClick={this.handleBackClick}
                />
                <Button
                  type="flat"
                  size="large"
                  label={i18n.translate`WindowStep - button 3`}
                  isDisabled={!(orderItem && orderItem.window)}
                  handleClick={this.handleCloseClick}
                />
              </ButtonGroup>
            </div>
          ) : (
            <div className={styles.windowSize}>
              <div className={styles.windowSizeWrapper}>
                <div className={styles.window}>
                  <div className={styles.windowGlass}>
                    <div className={styles.windowGlassHighlight1}></div>
                    <div className={styles.windowGlassHighlight2}></div>
                    <div className={styles.windowGlassHighlight3}></div>
                    <div className={styles.windowGlassHighlight4}></div>
                  </div>
                  <div className={styles.windowHeight}>
                    <label htmlFor="height">{i18n.translate`WindowStep - height`}</label>
                    <Input
                      type="number"
                      name="height"
                      min={1}
                      max={9999}
                      unit="mm"
                      value={this.state.height}
                      handleChange={this.handleHeightChange}
                    />
                  </div>
                  <div className={styles.windowWidth}>
                    <label htmlFor="width">{i18n.translate`WindowStep - width`}</label>
                    <Input
                      label={i18n.translate`WindowStep - width`}
                      type="number"
                      name="width"
                      min={1}
                      max={9999}
                      unit="mm"
                      value={this.state.width}
                      handleChange={this.handleWidthChange}
                    />
                  </div>
                </div>
                {!(this.state.width && this.state.height) || !filteredWindowSizes.length ? (
                  <p>{i18n.translate`WindowStep - button 1 disabled overlay - ${minWidth}:n ${maxWidth}:n ${minHeight}:n ${maxHeight}:n`}</p>
                ) : null}
              </div>

              <ButtonGroup align="center">
                <Button
                  type="flat"
                  size="large"
                  label={i18n.translate`WindowStep - button 1`}
                  overlayDisabledLabel={i18n.translate`WindowStep - button 1 disabled overlay - ${minWidth}:n ${maxWidth}:n ${minHeight}:n ${maxHeight}:n`}
                  isDisabled={!(this.state.width && this.state.height) || !filteredWindowSizes.length}
                  handleClick={this.handleSaveClick}
                />
              </ButtonGroup>
            </div>
          )}
        </div>
      </section>
    );
  }

  handleLeftScrollClick = () => {
    if (this.rootRef && this.rootRef.current) {
      $(this.rootRef.current).find(`.${styles.windowTypesList}`)[0].scrollLeft -= 100;
    }
  };

  handleRightScrollClick = () => {
    if (this.rootRef && this.rootRef.current) {
      $(this.rootRef.current).find(`.${styles.windowTypesList}`)[0].scrollLeft += 100;
    }
  };

  handleCloseClick = () => {
    openStepActionStream.push(null);
  };

  handleWidthChange = (value) => {
    if (!value) {
      this.setState({width: ''});

      return;
    }

    let width = parseInt(value.replace(NOT_DIGIT_REGEX, ''), 10);

    if (!_.isFinite(width)) {
      return;
    }

    this.setState({width});
  };

  handleHeightChange = (value) => {
    if (!value) {
      this.setState({height: ''});

      return;
    }

    let height = parseInt(value.replace(NOT_DIGIT_REGEX, ''), 10);

    if (!_.isFinite(height)) {
      return;
    }

    this.setState({height});
  };

  handleSaveClick = () => {
    if (!(this.state.width && this.state.height)) {
      return;
    }

    let {windowTypes, windowSizes, windowRecommendations} = dataStream.value;
    let filteredWindowSizes = windowSizes.filter(
      (windowSize) =>
        this.state.width >= windowSize.widths[0] &&
        this.state.width <= windowSize.widths[1] &&
        this.state.height >= windowSize.heights[0] &&
        this.state.height <= windowSize.heights[1],
    );
    let filteredWindowRecommendations = windowRecommendations.filter(
      (windowRecommendation) =>
        this.state.width >= windowRecommendation.widths[0] &&
        this.state.width <= windowRecommendation.widths[1] &&
        this.state.height >= windowRecommendation.heights[0] &&
        this.state.height <= windowRecommendation.heights[1],
    );
    let filteredWindowTypes = filteredWindowSizes.map((windowSize) => _.find(windowTypes, {type: windowSize.type}));

    if (filteredWindowRecommendations.length) {
      filteredWindowSizes.some((windowSize, windowSizeIndex) => {
        if (filteredWindowRecommendations[0].types.includes(windowSize.type)) {
          this.handleSelectWindow(filteredWindowTypes[windowSizeIndex], windowSize, 1);

          return true;
        }

        return false;
      });
    }

    this.setState({
      areDimensionsSaved: true,
    });
  };

  handleBackClick = () => {
    this.setState({
      areDimensionsSaved: false,
    });
  };

  handleSelectWindow = (windowType, windowSize, variant, currentColor) => {
    selectWindowActionStream.push({
      typeId: windowType.id,
      sizeId: windowSize.id,
      materialId: windowType.materialId,
      type: windowType.type,
      width: this.state.width,
      height: this.state.height,
      price: windowSize.price,
      hasLeftRightVariants: windowType.hasLeftRightVariants,
      variant,
    });

    // reset color
    if (currentColor && windowType.materialId ? !findMaterialWithinColor(currentColor, windowType.materialId) : false) {
      let {colors} = dataStream.value;
      selectColorActionStream.push(findDefaultColor(colors));
    }
  };
}
