/* eslint-disable quote-props */

import constants from '../../internals/constants';

const CS_CZ = constants.CS_CZ;
const SK_SK = constants.SK_SK;
const DE_DE = constants.DE_DE;

let i18nStrings: any = {
  'Html - title': {
    [CS_CZ]: 'Kalkulátor oken – okna na míru | OKNA – HNED',
    [SK_SK]: 'Kalkulátor okien - okná na mieru | OKNA – HNED',
    [DE_DE]: 'Fensterkonfigurator | FENSTER - JETZT',
  },

  'Html - description': {
    [CS_CZ]:
      'Vytvořte si vlastní okna na míru v jednoduchém kalkulátoru. Vaše vlastní plastová okna můžete mít na pár kliknutí.',
    [SK_SK]:
      'Vytvorte si vlastné okná na mieru v jednoduchom kalkulátora. Vaše vlastné plastové okná môžete mať na pár kliknutí.',
    [DE_DE]:
      'Wir bieten Ihnen einen übersichtlichen Online-Konfigurator der Kunststofffenster an, mit dem Sie selbst Ihr Kunststofffenster nach Maß in die Produktion einplanen.',
  },

  'windowType - variant': {
    [CS_CZ]: 'Otevírání',
    [SK_SK]: 'Otváranie',
    [DE_DE]: 'Öffnungsart',
  },

  'windowType - variant - left': {
    [CS_CZ]: 'levé',
    [SK_SK]: 'ľavé',
    [DE_DE]: 'links',
  },

  'windowType - variant - right': {
    [CS_CZ]: 'pravé',
    [SK_SK]: 'pravé',
    [DE_DE]: 'rechts',
  },

  'color - one side': {
    [CS_CZ]: 'venkovní',
    [SK_SK]: 'vonkajšie',
    [DE_DE]: 'Dekorfarbe nur außen',
  },

  'color - two sides': {
    [CS_CZ]: 'oboustranná',
    [SK_SK]: 'obojstranná',
    [DE_DE]: 'Dekorfarbe außen und innen',
  },

  /* Header */
  'Header - logo - claim': {
    [CS_CZ]: 'Kalkulátor – vytvořte si sami okna přesně podle svých představ!',
    [SK_SK]: 'Kalkulátor - vytvorte si sami okná presne podľa svojich predstáv!',
    [DE_DE]: 'Konfigurator - konfigurieren Sie Ihre Wunschfenster!',
  },

  'Header - window - configuring': {
    [CS_CZ]: 'nyní kalkulujete:',
    [SK_SK]: 'teraz kalkulujete:',
    [DE_DE]: 'jetzt konfigurieren:',
  },
  'Header - window - {0}': {
    [CS_CZ]: 'Okno #{0}',
    [SK_SK]: 'Okno #{0}',
    [DE_DE]: 'Fenster #{0}',
  },

  'Header - price - actual': {
    [CS_CZ]: 'Cena:',
    [SK_SK]: 'Cena',
    [DE_DE]: 'Preis',
  },
  'Header - price - {0}': {
    [CS_CZ]: '{0}',
    [SK_SK]: '{0}',
    [DE_DE]: '{0}',
  },
  'Header - price - no VAT': {
    [CS_CZ]: 'cena bez DPH:',
    [SK_SK]: 'cena bez DPH:',
    [DE_DE]: 'Preis ohne Mwst.',
  },
  'Header - price - VAT': {
    [CS_CZ]: 'cena včetně DPH:',
    [SK_SK]: 'cena vrátane DPH:',
    [DE_DE]: 'Preis MwSt. Inklusive',
  },
  'Header - videotutorial': {
    [CS_CZ]: 'Videonávod',
    [SK_SK]: 'Videonávod',
    [DE_DE]: 'Videoanleitung',
  },

  /* Footer */
  'Footer - links - about us': {
    [CS_CZ]: 'O nás',
    [SK_SK]: 'O nás',
    [DE_DE]: 'Über uns',
  },
  'Footer - links - terms': {
    [CS_CZ]: 'Obchodní podmínky',
    [SK_SK]: 'Obchodné podmienky',
    [DE_DE]: 'Handelsbedingungen',
  },
  'Footer - links - delivery': {
    [CS_CZ]: 'Doba dodání',
    [SK_SK]: 'Doba dodania',
    [DE_DE]: 'Lieferzeit',
  },
  'Footer - links - stored': {
    [CS_CZ]: 'Okna skladem',
    [SK_SK]: 'Okná skladom',
    [DE_DE]: 'Lagerfenster',
  },

  /* StepsList */
  'StepsList - window type - heading': {
    [CS_CZ]: 'Typ a rozměr okna',
    [SK_SK]: 'Typ a rozmer okna',
    [DE_DE]: 'Fensteryp und Fensterabmessung',
  },
  'StepsList - window type - button': {
    [CS_CZ]: 'Vybrat typ a rozměr okna',
    [SK_SK]: 'Vybrať typ a rozmer okna',
    [DE_DE]: 'Fensteryp und Fensterabmessung auswählen',
  },

  'StepsList - material - heading': {
    [CS_CZ]: 'Materiál',
    [SK_SK]: 'Materiál',
    [DE_DE]: 'Material ',
  },
  'StepsList - material - button': {
    [CS_CZ]: 'Vybrat materiál',
    [SK_SK]: 'Vybrať materiál',
    [DE_DE]: 'Material auswählen ',
  },

  'StepsList - glass - heading': {
    [CS_CZ]: 'Sklo / výplň',
    [SK_SK]: 'Sklo / PVC panel',
    [DE_DE]: 'Verglasung / Füllungen',
  },
  'StepsList - glass - button': {
    [CS_CZ]: 'Vybrat sklo',
    [SK_SK]: 'Vybrať sklo',
    [DE_DE]: 'Glas auswählen',
  },

  'StepsList - color - heading': {
    [CS_CZ]: 'Barva a dekor',
    [SK_SK]: 'Farba a dekor',
    [DE_DE]: 'Farbe und Dekor',
  },
  'StepsList - color - button': {
    [CS_CZ]: 'Vybrat barvu a dekor',
    [SK_SK]: 'Vybrať farbu a dekor',
    [DE_DE]: 'Farbe und Dekor auswählen',
  },

  'StepsList - accessories - heading': {
    [CS_CZ]: 'Doplňky',
    [SK_SK]: 'Doplnky',
    [DE_DE]: 'Zubehör',
  },
  'StepsList - accessories - button': {
    [CS_CZ]: 'Vybrat doplňky',
    [SK_SK]: 'Vybrať doplnky',
    [DE_DE]: 'Zubehör auswählen',
  },

  'StepsList - windows - heading': {
    [CS_CZ]: 'Moje okna',
    [SK_SK]: 'Moje okná',
    [DE_DE]: 'Meine Fenster',
  },
  'StepsList - windows - button': {
    [CS_CZ]: 'Přidat další okno',
    [SK_SK]: 'Pridať ďalšie okno',
    [DE_DE]: 'Fenster zufügen',
  },
  'StepsList - windows - button overlay': {
    [CS_CZ]: 'Přidejte do objednávky okno s jinými parametry',
    [SK_SK]: 'Pridajte do objednávky okno s inými parametrami',
    [DE_DE]: 'Fenster mit anderen Param',
  },

  'StepsList - order - heading': {
    [CS_CZ]: 'Objednávka',
    [SK_SK]: 'Objednávka',
    [DE_DE]: 'Bestellung',
  },
  'StepsList - order - button': {
    [CS_CZ]: 'Přehled objednávky',
    [SK_SK]: 'Prehľad objednávky',
    [DE_DE]: 'Bestellungsübersicht',
  },
  'StepsList - order - info': {
    [CS_CZ]: 'Pokud je vaše kalkulace dokončena, můžete přejít ke kontrole a vytvořit objednávku.',
    [SK_SK]: 'Pokiaľ je vaša kalkulácia dokončená, môžete prejsť ku kontrole a vytvoriť objednávku.',
    [DE_DE]:
      'Falls Ihre Bestellung gefertig ist, gehen Sie zur Bestellungsübersicht und platzieren Sie die Bestellung.',
  },

  /* Main */
  'Main - intro - heading': {
    [CS_CZ]: 'Kalkulátor oken',
    [SK_SK]: 'Kalkulátor okien',
    [DE_DE]: 'Fensterkonfigurator',
  },

  'Main - intro - button start': {
    [CS_CZ]: 'Začít s kalkulací',
    [SK_SK]: 'Začať s kalkuláciou',
    [DE_DE]: 'Jetzt konfigurieren',
  },

  'Main - intro - button videotutorial': {
    [CS_CZ]: 'Spustit videonávod',
    [SK_SK]: 'Spustiť videonávod',
    [DE_DE]: 'Videoanleitung starten',
  },

  'Main - visualisation - interior view': {
    [CS_CZ]: 'Vnitřní pohled',
    [SK_SK]: 'Vnútorný pohľad',
    [DE_DE]: 'Innenansicht',
  },

  'Main - visualisation - exterior view': {
    [CS_CZ]: 'Vnější pohled',
    [SK_SK]: 'Vonkajší pohľad',
    [DE_DE]: 'Außenansicht',
  },

  'Main - intro - text 1': {
    [CS_CZ]:
      'Nabízíme přehledný online kalkulátor plastových oken, pomocí kterého si sami zadáte plastové okno na míru přímo do výroby!',
    [SK_SK]:
      'Ponúkame prehľadný online kalkulátor plastových okien, pomocou ktorého si sami zadáte plastové okno na mieru priamo do výroby!',
    [DE_DE]:
      'Wir bieten Ihnen einen übersichtlichen Online-Konfigurator der Kunststofffenster an, mit dem Sie selbst Ihr Kunststofffenster nach Maß in die Produktion einplanen.',
  },

  /* WindowStep */
  'WindowStep - window detail - price - {0}': {
    [CS_CZ]: '{0}',
    [SK_SK]: '{0}',
    [DE_DE]: '{0}',
  },

  'WindowStep - window detail - recommended': {
    [CS_CZ]: 'Doporučené',
    [SK_SK]: 'Odporúčané',
    [DE_DE]: 'Wir empfehlen',
  },

  'WindowStep - heading 1': {
    [CS_CZ]: 'Rozměry okna nebo dveří',
    [SK_SK]: 'Rozmery okna alebo dverí',
    [DE_DE]: 'Fenster- oder Türabmessung',
  },

  'WindowStep - heading 2': {
    [CS_CZ]: 'Pro zadané rozměry jsou dostupné tyto typy oken',
    [SK_SK]: 'Pre zadané rozmery sú dostupné tieto typy okien',
    [DE_DE]: 'Für ausgewählte Größen sind diese Fenstertypen verfügbar',
  },

  'WindowStep - width': {
    [CS_CZ]: 'Šířka',
    [SK_SK]: 'Šírka',
    [DE_DE]: 'Breite',
  },

  'WindowStep - height': {
    [CS_CZ]: 'Výška',
    [SK_SK]: 'Výška',
    [DE_DE]: 'Höhe',
  },

  'WindowStep - button 1': {
    [CS_CZ]: 'Uložit rozměry',
    [SK_SK]: 'Uložiť rozmery',
    [DE_DE]: 'Größen speichern',
  },

  'WindowStep - button 1 disabled overlay - {0} {1} {2} {3}': {
    [CS_CZ]: 'Máme okna o velikostech {0} – {1} × {0} – {1} mm',
    [SK_SK]: 'Máme okná o veľkostiach {0} – {1} × {0} – {1} mm',
    [DE_DE]: 'Wir bieten Fenster mit Größen {0} – {1} × {0} – {1} mm',
  },

  'WindowStep - button 2': {
    [CS_CZ]: 'Změnit rozměry',
    [SK_SK]: 'Zmeniť rozmery',
    [DE_DE]: 'Größen ändern / aktualisieren',
  },

  'WindowStep - button 3': {
    [CS_CZ]: 'Potvrdit výběr typu okna a pokračovat',
    [SK_SK]: 'Potvrdiť výber typu okna a pokračovať',
    [DE_DE]: 'Auswahl des Fenstertyps bestätigen und weiterleiten',
  },

  /* 'WindowStep - warning': {
    [CS_CZ]: 'Výběr okna nastaví materiál, sklo a dekor na výchozí hodnotu',
    [SK_SK]: 'Výber okná nastaví materiál, sklo a dekor na základnú hodnotu',
    [DE_DE]: 'Auswahl des Fensters stellt Material, Glas und Dekor auf Ausgangswert ein',
  }, */

  'WindowStep - window not available in color': {
    [CS_CZ]: 'Výběrem těchto dveří bude barva nastavena na bílou',
    [SK_SK]: 'Výberom týchto dverí bude farba nastavená na bielu',
    [DE_DE]: 'Wenn Sie diese Tür auswählen, wird die Farbe auf Weiß gesetzt',
  },

  'WindowStep - warning': {
    [CS_CZ]:
      'Výška okna je uvedena bez 30mm podkladního profilu, který je součástí každého okna a lze jej jednoduše demontovat.',
    [SK_SK]:
      'Výška okna je uvedená bez 30mm podkladového profilu, ktorý je súčasťou každého okna a možno ho jednoducho demontovať.',
    [DE_DE]:
      'Fensterhöhe ist ohne 30 mm Grundprofil angegeben, das Profil ist ein Teil jedes Fensters und kann leicht demontiert werden.',
  },

  'WindowStep - info 1': {
    [CS_CZ]: 'Klika je součástí každého okna a dveří.',
    [SK_SK]: 'Klika je súčasťou každého okna a dverí.',
    [DE_DE]: 'Fenster und Türen werden inklusive Griffe geliefert.',
  },

  /* MaterialStep */
  'MaterialStep - material detail - positive price - {0}': {
    [CS_CZ]: '+{0}',
    [SK_SK]: '+{0}',
    [DE_DE]: '+{0}',
  },

  'MaterialStep - material detail - negative price - {0}': {
    [CS_CZ]: '−{0}',
    [SK_SK]: '−{0}',
    [DE_DE]: '−{0}',
  },

  'MaterialStep - heading 1': {
    [CS_CZ]: 'Materiál',
    [SK_SK]: 'Materiál',
    [DE_DE]: 'Material',
  },

  'MaterialStep - button 1': {
    [CS_CZ]: 'Potvrdit výběr a pokračovat',
    [SK_SK]: 'Potvrdiť výber a pokračovať',
    [DE_DE]: 'Auswahl bestätigen und weiterleiten',
  },

  'MaterialStep - warning': {
    [CS_CZ]: 'Změna materiálu automaticky nastaví bílou barvu a základní sklo.',
    [SK_SK]: 'Zmena materiálu automaticky nastaví bielu farbu a základné sklo.',
    [DE_DE]: 'Mit jeder Materialänderung werden die weiße Farbe und Grundglas eingestellt.',
  },

  /* GlassStep */
  'GlassStep - glass detail - positive price - {0}': {
    [CS_CZ]: '+{0}',
    [SK_SK]: '+{0}',
    [DE_DE]: '+{0}',
  },

  'GlassStep - glass detail - negative price - {0}': {
    [CS_CZ]: '−{0}',
    [SK_SK]: '−{0}',
    [DE_DE]: '−{0}',
  },

  'GlassStep - heading 1': {
    [CS_CZ]: 'Sklo',
    [SK_SK]: 'Sklo',
    [DE_DE]: 'Glas',
  },

  'GlassStep - button 1': {
    [CS_CZ]: 'Potvrdit výběr a pokračovat',
    [SK_SK]: 'Potvrdiť výber a pokračovať',
    [DE_DE]: 'Auswahl bestätigen und weiterleiten',
  },

  /* ColorStep */
  'ColorStep - color detail - positive price - {0}': {
    [CS_CZ]: '+{0}',
    [SK_SK]: '+{0}',
    [DE_DE]: '+{0}',
  },

  'ColorStep - color detail - negative price - {0}': {
    [CS_CZ]: '−{0}',
    [SK_SK]: '−{0}',
    [DE_DE]: '−{0}',
  },

  'ColorStep - heading 1': {
    [CS_CZ]: 'Barva a dekor',
    [SK_SK]: 'Farba a dekor',
    [DE_DE]: 'Farbe und Dekor',
  },

  'ColorStep - sides 1': {
    [CS_CZ]: 'Vnější barva',
    [SK_SK]: 'Vonkajšia farba',
    [DE_DE]: 'Außenfarbe',
  },

  'ColorStep - sides 2': {
    [CS_CZ]: 'Oboustranná barva',
    [SK_SK]: 'Obojstranná farba',
    [DE_DE]: 'Beidseitige Farbe',
  },

  'ColorStep - warning': {
    [CS_CZ]: 'Vnější barva je dle vašeho výběru, vnitřní barva je vždy bílá.',
    [SK_SK]: 'Vonkajšia farba je podľa vášho výberu, vnútorná farba je vždy biela.',
    [DE_DE]: 'Außenfarbe nach Ihrer Auswahl, Innenfarbe ist immer weiß',
  },

  'ColorStep - color category 1': {
    [CS_CZ]: 'Základní',
    [SK_SK]: 'Základná',
    [DE_DE]: 'Grund',
  },

  'ColorStep - color category - {0}': {
    [CS_CZ]: 'Skupina {0}',
    [SK_SK]: 'Skupina {0}',
    [DE_DE]: 'Gruppe {0}',
  },

  'ColorStep - button 1': {
    [CS_CZ]: 'Potvrdit výběr a pokračovat',
    [SK_SK]: 'Potvrdiť výber a pokračovať',
    [DE_DE]: 'Auswahl bestätigen und weiterleiten',
  },

  /* AccessoriesStep */
  'AccessoriesStep - accessory detail - price - {0}': {
    [CS_CZ]: '{0}',
    [SK_SK]: '{0}',
    [DE_DE]: '{0}',
  },

  'AccessoriesStep - warning': {
    [CS_CZ]:
      'Pokud chcete více než jeden kus doplňků, můžete jejich počet upravit po ukončení kalkulace a přesměrování do košíku.',
    [SK_SK]:
      'Ak chcete viac ako jeden kus doplnkov, môžete ich počet upraviť po ukončení kalkulácie a presmerovanie do košíka.',
    [DE_DE]:
      'Wenn Sie mehr als ein Zubehör Artikel möchten, können Sie die Anzahl anpassen, nachdem die Konfiguration agefertigt ist und Sie auf den Warenkorb  geleitet.',
  },

  'AccessoriesStep - info 1': {
    [CS_CZ]: 'Okna a dveře dodáváme kompletní včetně klik a krytek.',
    [SK_SK]: 'Okná a dvere dodávame kompletný vrátane klik a krytiek.',
    [DE_DE]: 'Fenster und Türe werden komplett mit Griffen und Deckung geliefert.',
  },

  'AccessoriesStep - info 2': {
    [CS_CZ]:
      'Další doplňky jako montážní sady, kotvy, šrouby, podložky, montážní pěnu nebo další příslušenství k oknům a dveřím, jako kliky, sítě proti hmyzu, čistící sady apod. si můžete pohodlně doobjednat po přenesní Vaší objednávky do košíku našeho eshopu.<br />Veškeré námi nabízené doplňky najdete na našem eshopu <a target="_blank" rel="noopener noreferrer" href="https://www.okna-hned.cz/26-prislusenstvi-k-oknum">www.okna-hned.cz</a> v sekci  "Příslušenství".',
    [SK_SK]:
      'Ďalšie doplnky ako montážne sady, kotvy, skrutky, podložky, montážne peny alebo ďalšie príslušenstvo k oknám a dverám, ako kľučky, siete proti hmyzu, čistiace sady a pod. si môžete pohodlne doobjednať po prenesenie Vašej objednávky do košíka nášho e-shopu.<br />Všetky nami ponúkané doplnky nájdete v našom e-shope <a target="_blank" rel="noopener noreferrer" href="https://www.https://www.okna-hned.sk/26-prislusenstvo-k-oknam">www.okna-hned.sk</a> v sekcii "Príslušenstvo".',
    [DE_DE]:
      'Weiteres Zubehör wie Montagesätze, Fensterrahmenlaschen, Fensterrahmenschrauben, Unterlegscheiben, Montageschaum oder weiteres Zubehör für Fenster und Türen wie Klinken, Insektennetze, Reinigungssätze usw. können Sie bequem nach Ihrer Bestellung im Warenkorb unseres E-shops bestellen.<br />Sämtliches Zubehör finden Sie in unserem E-shop unter <a target="_blank" rel="noopener noreferrer" href="https://www.fenster-jetzt.de/26-fenstermontage-zubehoer">www.fenster-jetzt.de</a> im Bereich "Zubehör".',
  },

  'AccessoriesStep - heading 1': {
    [CS_CZ]: 'Výběr doplňků',
    [SK_SK]: 'Výber doplnkov',
    [DE_DE]: 'Zubehör Auswahl',
  },

  'AccessoriesStep - heading 2': {
    [CS_CZ]: 'Výběr žaluzií',
    [SK_SK]: 'Výber žalúzií',
    [DE_DE]: 'Jalousie Auswahl',
  },

  'AccessoriesStep - heading 3': {
    [CS_CZ]: 'Výběr vnitřního parapetu',
    [SK_SK]: 'Výber vnútorného parapetu',
    [DE_DE]: 'Innenfensterbank Auswahl',
  },

  'AccessoriesStep - heading 4': {
    [CS_CZ]: 'Výběr vnějšího parapetu',
    [SK_SK]: 'Výber vonkajšieho parapetu',
    [DE_DE]: 'Außenfensterbank Auswahl',
  },

  'AccessoriesStep - heading 5': {
    [CS_CZ]: 'Výběr dalších doplňků',
    [SK_SK]: 'Výber ďalších doplnkov',
    [DE_DE]: 'Weiteres Zubehör',
  },

  'AccessoriesStep - heading 6': {
    [CS_CZ]: 'Žaluzie',
    [SK_SK]: 'Žalúzie',
    [DE_DE]: 'Jalousie',
  },

  'AccessoriesStep - heading 7': {
    [CS_CZ]: 'Vnitřní parapet',
    [SK_SK]: 'Vnútorný parapet',
    [DE_DE]: 'Innenfensterbank',
  },

  'AccessoriesStep - heading 8': {
    [CS_CZ]: 'Vnější parapet',
    [SK_SK]: 'Vonkajší parapet',
    [DE_DE]: 'Außenfensterbank',
  },

  'AccessoriesStep - heading 9': {
    [CS_CZ]: 'Další doplněk',
    [SK_SK]: 'Ďalší doplnok',
    [DE_DE]: 'Weiteres Zubehör',
  },

  'AccessoriesStep - button 1': {
    [CS_CZ]: 'Potvrdit výběr a pokračovat',
    [SK_SK]: 'Potvrdiť výber a pokračovať',
    [DE_DE]: 'Auswahl bestätigen und weiterleiten',
  },

  'AccessoriesStep - button 2': {
    [CS_CZ]: 'Potvrdit výběr a pokračovat',
    [SK_SK]: 'Potvrdiť výber a pokračovať',
    [DE_DE]: 'Auswahl bestätigen und weiterleiten',
  },

  'AccessoriesStep - button 3': {
    [CS_CZ]: 'Potvrdit výběr a pokračovat',
    [SK_SK]: 'Potvrdiť výber a pokračovať',
    [DE_DE]: 'Auswahl bestätigen und weiterleiten',
  },

  'AccessoriesStep - button 4': {
    [CS_CZ]: 'Potvrdit výběr a pokračovat',
    [SK_SK]: 'Potvrdiť výber a pokračovať',
    [DE_DE]: 'Auswahl bestätigen und weiterleiten',
  },

  'AccessoriesStep - button 5': {
    [CS_CZ]: 'Potvrdit výběr a pokračovat',
    [SK_SK]: 'Potvrdiť výber a pokračovať',
    [DE_DE]: 'Auswahl bestätigen und weiterleiten',
  },

  'AccessoriesStep - category heading 1': {
    [CS_CZ]: 'Žaluzie',
    [SK_SK]: 'Žalúzie',
    [DE_DE]: 'Jalousie',
  },

  'AccessoriesStep - category heading 2': {
    [CS_CZ]: 'Parapety',
    [SK_SK]: 'Parapety',
    [DE_DE]: 'Fensterbank',
  },

  'AccessoriesStep - category heading 3': {
    [CS_CZ]: 'Další doplňky',
    [SK_SK]: 'Ďalšie doplnky',
    [DE_DE]: 'Weiteres Zubehör',
  },

  'AccessoriesStep - accessory - price - {0}': {
    [CS_CZ]: '{0}',
    [SK_SK]: '{0}',
    [DE_DE]: '{0}',
  },

  'AccessoriesStep - category button 1': {
    [CS_CZ]: 'Vybrat žaluzie',
    [SK_SK]: 'Vybrať žalúzie',
    [DE_DE]: 'Jalousie auswählen',
  },

  'AccessoriesStep - category button 2': {
    [CS_CZ]: 'Odebrat žaluzie',
    [SK_SK]: 'Odobrať žalúzie',
    [DE_DE]: 'Jalousie entfernen',
  },

  'AccessoriesStep - category button 3': {
    [CS_CZ]: 'Vybrat vnitřní parapet',
    [SK_SK]: 'Vybrať vnútorný parapet',
    [DE_DE]: 'Innenfensterbank auswählen',
  },

  'AccessoriesStep - category button 4': {
    [CS_CZ]: 'Vybrat vnější parapet',
    [SK_SK]: 'Vybrať vonkajši parapet',
    [DE_DE]: 'Außenfensterbank auswählen ',
  },

  'AccessoriesStep - category button 5': {
    [CS_CZ]: 'Odebrat vnitřní parapet',
    [SK_SK]: 'Odobrať vnútorný parapet',
    [DE_DE]: 'Innenfensterbank entfernen',
  },

  'AccessoriesStep - category button 6': {
    [CS_CZ]: 'Odebrat vnější parapet',
    [SK_SK]: 'Odobrať vonkajši parapet',
    [DE_DE]: 'Außenfensterbank entfernen',
  },

  'AccessoriesStep - category button 7': {
    [CS_CZ]: 'Vybrat další doplňky',
    [SK_SK]: 'Vybrať ďalšie doplnky',
    [DE_DE]: 'Weiteres Zubehör auswählen',
  },

  'AccessoriesStep - select dimensions': {
    [CS_CZ]: 'Zadejte rozměry',
    [SK_SK]: 'Zadajte rozmery',
    [DE_DE]: 'Größe eingeben',
  },

  'AccessoriesStep - dimension - width': {
    [CS_CZ]: 'Délka',
    [SK_SK]: 'Dĺžka',
    [DE_DE]: 'Länge',
  },

  'AccessoriesStep - dimension - depth': {
    [CS_CZ]: 'Hloubka',
    [SK_SK]: 'Hĺbka',
    [DE_DE]: 'Tiefe',
  },

  'AccessoriesStep - select color': {
    [CS_CZ]: 'Zvolte barvu',
    [SK_SK]: 'Zvoľte farbu',
    [DE_DE]: 'Wählen Sie Farbe aus',
  },

  /* OrderStep */
  'OrderStep - order item link - heading - {0}': {
    [CS_CZ]: 'Okno #{0}',
    [SK_SK]: 'Okno #{0}',
    [DE_DE]: 'Fenster #{0}',
  },

  'OrderStep - order item link - button 1': {
    [CS_CZ]: 'Přidat',
    [SK_SK]: 'Pridať',
    [DE_DE]: 'Hinzufügen',
  },

  'OrderStep - order item link - button 2': {
    [CS_CZ]: 'Odebrat',
    [SK_SK]: 'Odobrať',
    [DE_DE]: 'Entfernen',
  },

  'OrderStep - order item link - button 3': {
    [CS_CZ]: 'Smazat',
    [SK_SK]: 'Zmazať',
    [DE_DE]: 'Löschen',
  },

  'OrderStep - order item link - button 4': {
    [CS_CZ]: 'Přejít na toto okno',
    [SK_SK]: 'Prejsť na toto okno',
    [DE_DE]: 'Zu diesem Fenster übergehen',
  },

  'OrderStep - order item detail - property label 1': {
    [CS_CZ]: 'Cena za kus bez DPH',
    [SK_SK]: 'Cena za kus bez DPH',
    [DE_DE]: 'Preis pro Stück ohne MwSt.',
  },

  'OrderStep - order item detail - property label 2': {
    [CS_CZ]: 'Typ okna',
    [SK_SK]: 'Typ okna',
    [DE_DE]: 'Fenstertyp',
  },

  'OrderStep - order item detail - property label 3': {
    [CS_CZ]: 'Materiál',
    [SK_SK]: 'Materiál',
    [DE_DE]: 'Material',
  },

  'OrderStep - order item detail - property label 4': {
    [CS_CZ]: 'Sklo',
    [SK_SK]: 'Sklo',
    [DE_DE]: 'Glas',
  },

  'OrderStep - order item detail - property label 5': {
    [CS_CZ]: 'Barva a dekor',
    [SK_SK]: 'Farba a dekor',
    [DE_DE]: 'Farbe und Dekor',
  },

  'OrderStep - order item detail - property label 6': {
    [CS_CZ]: 'Doplňky',
    [SK_SK]: 'Doplnky',
    [DE_DE]: 'Zubehör',
  },

  'OrderStep - order item detail - property label 7': {
    [CS_CZ]: 'Počet',
    [SK_SK]: 'Počet',
    [DE_DE]: 'Anzahl',
  },

  'OrderStep - order item detail - property label 8': {
    [CS_CZ]: 'Cena celkem bez DPH',
    [SK_SK]: 'Cena celkom bez DPH',
    [DE_DE]: 'Gesamtpreis ohne MwSt. ',
  },

  'OrderStep - order item detail - property label 9': {
    [CS_CZ]: 'Žaluzie',
    [SK_SK]: 'Žalúzie',
    [DE_DE]: 'Jalousie',
  },

  'OrderStep - order item detail - property label 10': {
    [CS_CZ]: 'Vnitřní parapet',
    [SK_SK]: 'Vnútorný parapet',
    [DE_DE]: 'Innenfensterbank',
  },

  'OrderStep - order item detail - property label 11': {
    [CS_CZ]: 'Vnější parapet',
    [SK_SK]: 'Vonkajši parapet',
    [DE_DE]: 'Außenfensterbank',
  },

  'OrderStep - order item detail - property label 12': {
    [CS_CZ]: 'Další doplňky',
    [SK_SK]: 'Ďalšie doplnky',
    [DE_DE]: 'Weiteres Zubehör',
  },

  'OrderStep - order item detail - property label 13': {
    [CS_CZ]: 'Cena celkem včetně DPH',
    [SK_SK]: 'Cena celkom vrátane DPH',
    [DE_DE]: 'Gesamtpreis MwSt. Inklusive',
  },

  'OrderStep - order item detail - property value - {0}': {
    [CS_CZ]: '{0}',
    [SK_SK]: '{0}',
    [DE_DE]: '{0}',
  },

  'OrderStep - heading 1': {
    [CS_CZ]: 'Nakalkulovaná okna',
    [SK_SK]: 'Nakalkulovaná okná',
    [DE_DE]: 'Berechnete Fenster',
  },

  'OrderStep - button 1': {
    [CS_CZ]: 'Vrátit se ke kalkulaci',
    [SK_SK]: 'Vrátiť sa na kalkuláciu',
    [DE_DE]: 'Zurück zur Konfiguration',
  },

  'OrderStep - button 2': {
    [CS_CZ]: 'Přejít k objednávce',
    [SK_SK]: 'Prejsť k objednávke',
    [DE_DE]: 'Zur Bestellung',
  },

  'OrderStep - phone': {
    [CS_CZ]: 'Vaše telefonní číslo',
    [SK_SK]: 'Vaše telefonní číslo',
    [DE_DE]: 'Ihre Telefonnummer',
  },
};

export default i18nStrings;
