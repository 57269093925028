import router from './router';
import {RouteStream} from '../libs/Router';
import constants from '../internals/constants';
import urlAppRoot from '../internals/urlAppRoot';

const CS_CZ = constants.CS_CZ;
const SK_SK = constants.SK_SK;
const DE_DE = constants.DE_DE;
const DE_AT = constants.DE_AT;

let origin = CS_CZ;

if (global.location.origin.indexOf('.sk') >= 0) {
  origin = SK_SK;
}

if (global.location.origin.indexOf('.de') >= 0 || global.location.origin.indexOf('.eu') >= 0) {
  origin = DE_DE;
}

if (global.location.origin.indexOf('.at') >= 0) {
  origin = DE_AT;
}

let routeStream: RouteStream = router.add(`${urlAppRoot}(/)(:language)(/order)(/:order)(/)`).map((value) => {
  let {language, order} = value;
  let routeString = '';

  if (language) {
    routeString += `/${language}`;
  }

  if (order) {
    routeString += `/${order}`;
  }

  if (!routeString) {
    routeString = '/';
  }

  // (global as any).ga('set', 'page', routeString);
  // (global as any).ga('send', 'pageview');

  if (
    typeof window !== 'undefined' &&
    window.history &&
    window.history.state &&
    typeof window.history.state.scroll !== 'undefined'
  ) {
    requestAnimationFrame(() => {
      window.scrollTo(0, window.history.state.scroll);
    });
  }

  if (language === 'order') {
    language = origin;
  }

  if (language !== CS_CZ && language !== SK_SK && language !== DE_DE && language !== DE_AT) {
    order = language;
    language = origin;
  }

  return {language, order};
});

(global as any).routeStream = routeStream;

export default routeStream;
